import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import {actionPitchUpdate} from './pitch.providers';
import {SystemStatus, Steps} from '../../Common/Enums';
import FormInstructions from './FormInstructions';

CheckupDevelopment.propTypes = {
  successCallback: PropTypes.any,
  history: PropTypes.object,
  getPitchDetails: PropTypes.func.isRequired,
};

function CheckupDevelopment(props) {

  const dispatch = useDispatch();
  const {currentCompany} = useSelector(state => state.pitch);
  const [company, setCompany] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setCompany({...currentCompany});
  }, [currentCompany]);

  const save = saveMode => {
    setIsSaving(true);
    company.system_status = saveMode;

    dispatch(actionPitchUpdate(company, (success, message, updateResult) => {
      if (!success) {
        toast.info(message);
      } else {
        if (updateResult.changedRows === 0) {
          toast.info('Seus dados já estão salvos. Nenhuma informação foi alterada.');
        } else {
          toast.success('💾 Informações atualizadas com sucesso!');
          props.getPitchDetails();
        }
      }
      setIsSaving(false);
    }));
  };

  const handleCompanyUpdate = (field, value) => {
    setCompany({...company, [field]: value});
  };
  const isDisabled = () => {
    return company && (currentCompany.system_status === SystemStatus.finalizado || currentCompany.etapa_atual !== Steps.etapa4.value);
  };

  return company && (
    <div className="mauticform_wrapper" style={{padding: "0.8rem"}}>

      <FormInstructions materialIconName="trending_up">
        Nós precisamos entender quais são os desafios que sua startup enfrenta e por isso essa etapa busca identificar a maturidade do negócio, como canais de venda, formação das receitas, entre outras informações relevantes. Quanto mais assertivo você for nas respostas, mais rápido poderemos entender como ajudar nos desafios futuros.
      </FormInstructions>

      <form autoComplete="false" method="post" encType="multipart/form-data">
        
        <div className="mauticform-innerform">

          <div className="mauticform-page-wrapper mauticform-page-1">

            <div className="mauticform-row mauticform-freetext mauticform-field-1">
              <h3 className="mauticform-label">
                Checkup de Maturidade
              </h3>
              <div className="mauticform-freetext">
                Preencha com atenção todos os campos.
              </div>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Quanto tempo sua startup tem de Operação?</label>
              <Select
                fullWidth
                value={company.quanto_sua_startup_tem_de}
                onChange={e => handleCompanyUpdate('quanto_sua_startup_tem_de', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Menos de um ano">Menos de um ano</MenuItem>
                <MenuItem value="Até 2 anos">Até 2 anos</MenuItem>
                <MenuItem value="De 3 a 5 anos">De 3 a 5 anos</MenuItem>
                <MenuItem value="Mais de 5 anos">Mais de 5 anos</MenuItem>
                <MenuItem value="Não estamos operacionalizados">Não estamos operacionalizados</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Se já tem clientes, quantos?</label>
              <Select
                fullWidth
                value={company.jatemclientes}
                onChange={e => handleCompanyUpdate('jatemclientes', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Abaixo de 20">Abaixo de 20</MenuItem>
                <MenuItem value="Entre 20 e 100">Entre 20 e 100</MenuItem>
                <MenuItem value="Entre 100 e 500">Entre 100 e 500</MenuItem>
                <MenuItem value="Entre 500 e 5.000">Entre 500 e 5.000</MenuItem>
                <MenuItem value="Acima de 5.000">Acima de 5.000</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Atingiu PMF (Product Market Fit)?</label>
              <Select
                fullWidth
                value={company.atingiupmf}
                onChange={e => handleCompanyUpdate('atingiupmf', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Sim">Sim</MenuItem>
                <MenuItem value="Não">Não</MenuItem>
                <MenuItem value="Não Sei">Não Sei</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Qual foi o faturamento da sua Startup no último ano?</label>
              <Select
                fullWidth
                value={company.temreceitaqualvaloranual}
                onChange={e => handleCompanyUpdate('temreceitaqualvaloranual', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Não tenho receita ainda">Não tenho receita ainda</MenuItem>
                <MenuItem value="Abaixo de 100 mil">Abaixo de 100 mil</MenuItem>
                <MenuItem value="Entre 100 e 500 mil">Entre 100 e 500 mil</MenuItem>
                <MenuItem value="Entre 500 e 1 milhão">Entre 500 e 1 milhão</MenuItem>
                <MenuItem value="Acima de 1 milhão">Acima de 1 milhão</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Tem lucro? Qual?</label>
              <Select
                fullWidth
                value={company.temlucro}
                onChange={e => handleCompanyUpdate('temlucro', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Estou trabalhando com prejuízo">Estou trabalhando com prejuízo</MenuItem>
                <MenuItem value="Menor do que 5 % da receita">Menor do que 5 % da receita</MenuItem>
                <MenuItem value="Entre 5 e 10 %">Entre 5 e 10 %</MenuItem>
                <MenuItem value="Entre 10 e 20 %">Entre 10 e 20 %</MenuItem>
                <MenuItem value="Acima de 20%">Acima de 20%</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Qual a distribuição entre receitas recorrentes e não recorrentes?</label>
              <Select
                fullWidth
                value={company.qualdistrubuicaoreceitasr}
                onChange={e => handleCompanyUpdate('qualdistrubuicaoreceitasr', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Não temos receita recorrente e nem receita de vendas avulsas">Não temos receita recorrente e nem receita de vendas avulsas</MenuItem>
                <MenuItem value="Não temos receita recorrente, somente vendas avulsas (setup fee, projetos pontuais, consultorias, take rate etc)">Não temos receita recorrente, somente vendas avulsas (setup fee, projetos pontuais, consultorias, take rate etc)</MenuItem>
                <MenuItem value="Temos receita recorrente, mas não representa nem 20% das receitas totais">Temos receita recorrente, mas não representa nem 20% das receitas totais</MenuItem>
                <MenuItem value="Temos receita recorrente, representando de 21% até 70% das receitas totais">Temos receita recorrente, representando de 21% até 70% das receitas totais</MenuItem>
                <MenuItem value="Mais de 70% da nossa receitas é recorrente">Mais de 70% da nossa receitas é recorrente</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-button-wrapper mauticform-field-37">
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0 0 1.5rem"}}>

                <>
                {!isDisabled() &&
                <button 
                  type="button" 
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.rascunho)}
                  disabled={isDisabled()}
                >
                  Salvar Rascunho
                </button>
                }

                <div style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                  {isSaving && <CircularProgress />}
                  {(isDisabled() && !isSaving) && <>Etapa Finalizada 🎉</>}
                </div>

                {!isDisabled() &&
                <button
                  type="button"
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.finalizado)}
                  disabled={isDisabled()}
                >
                  Finalizar Etapa
                </button>
                }
                </>

              </div>
            </div>

          </div>
        </div>

      </form>
    </div>
  );
}

export default CheckupDevelopment;
