import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { actionLogin } from "./login.providers";

Login.propTypes = {
  history: PropTypes.object
};

function Login(props) {

  const matches = useMediaQuery('(min-width:993px)');

  const dispatch = useDispatch();
  const [form, setForm] = useState({
    user: '',
    password: '',
    loading: false
  });

  const handleFormInputChange = input => event => {
    setForm({ ...form, [input]: event.target.value });
  };

  const doLogin = () => {
    setForm({ ...form, loading: true });
    dispatch(
      actionLogin(form.user, form.password, (success, message) => {
        setForm({ ...form, loading: false });
        if (success) {
          props.history.push(`pitch`);
        } else {
          toast.info(message);
        }
      })
    );
  };

  return (
    <>
    <div className="row" 
      style={{position: "absolute", width: "100%", backgroundColor: !matches && "#fff1", zIndex: "20", paddingTop: "1rem", paddingBottom: "1rem"}}
      >
      <div className="col s12 padding-0" style={{display: "flex", alignItems: "center"}}>
        <div className="col s2" style={{textAlign: "right"}}>
          <img src="/app-assets/images/logo/pateo76_logo_branco.png" width={matches ? "168px": "110px"} />
        </div>
        <div className={matches ? "col s8" : "col s10"} style={{textAlign: "right"}}>
          <a href="https://pateo76.com.br/" aria-current="page" data-ps2id-api="true">
            <span className={`login-btn ${matches && 'menuTopOver'}`} style={{paddingRight: matches ? "2rem" : "1.5rem", fontSize: !matches && "1rem"}}>HOME</span>
          </a>
          <a href="https://pateo76.com.br/quem-somos" data-ps2id-api="true">
            <span className={`login-btn ${matches && 'menuTopOver'}`} style={{fontSize: !matches && "1rem"}}>QUEM SOMOS</span>
          </a>
        </div>
        {matches && <div className="col s2" style={{textAlign: "right"}} />}
      </div>
    </div>

    <div id="login-page" className="blank-page" style={{position: "relative", zIndex: "10"}}>
      <div className="row" style={{
        display: matches ? "block" : "flex",
        flexDirection: matches ? "row" : "column-reverse",
        backgroundImage: `url('/app-assets/images/gallery/cycle2-login-background.webp')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        }}>

        <div className="col s12 l6"
          style={{
            // backgroundColor: "#000",
            color: "#fff",
            minHeight: matches ? "100vh" : "0",
            padding: matches ? "6.5rem 5rem" : "2rem",
            fontSize: matches ? "1rem" : "1.2rem",
            lineHeight: "1.8rem"
          }}
          >

          <div style={{marginBottom: "1.5rem"}}>
            <img src="/app-assets/images/logo/logo_ac_boost_2022-03.webp" alt="" style={{width: "10rem", marginRight: "2rem"}} />
          </div>
          Ac boost é o programa de Impulsionamento de startups do Pateo76, o HUB de Inovação
          desenvolvido pelo Conselho de Inovação da Associação Comercial de São Paulo - Conin, com o
          objetivo de preparar startups para sua próxima fase, possibilitando a elas estruturas mais
          qualificadas, conexões com empreendedores, ampla rede de consultores, mentores, investidores,
          parceiros corporativos e governamentais.<br />
          <br />
          As startups com melhores desempenhos receberão o Selo de Inovação da ACSP que chegou para
          democratizar o acesso à tecnologia para os empreendedores. O Programa conta com a parceria do
          Sebrae/SP e o apoio de parceiros reconhecidos pelo Ecossistema de Inovação, entre eles,
          BossaNova, AdeSampa, Oasis Lab, ABIESV, Voe Sem Asas, entre outros.<br />
          <br />
          Para a ACSP, é uma oportunidade de encontrar parceiros para a criação de produtos e serviços
          inovadores que serão oferecidos para o Associado.<br />
          <br />
          <b>Não perca tempo. Inscreva a sua startup agora mesmo.<br />
          Ac boost é a química entre sua startup e o sucesso.</b>
        </div>

        <div className="col s12 l6" style={{margin: 0, padding: 0}}>
          <div style={{margin: 0, padding: 0, height: "100vh", width: "100%", borderColor: "transparent", borderRadius: "0px 240px 0px 0px", imageRepeat: "no-repeat"}}>
            <div style={{float: "left", position: "absolute", marginTop: "8rem", display: !matches && "none"}}>
              <img src="/app-assets/images/gallery/quadrados-login.png" alt="" />
            </div>

            <div className="container box-login" style={{height: "100vh"}}>
              <div style={{marginBottom: "1.5rem"}}></div>
              <div style={{marginBottom: "0.2rem"}}></div>

              <div className="row center-card" style={{width: "100%", zIndex: 2}}>
                <div className="col s12 m10 z-depth-4 card-panel border-radius-6 login-card">
                  <form className="login-form">
                    <div className="row">
                      <div className="input-field col s12">
                        <span className="ml-1 login-font-box" style={{fontSize: !matches && "1.65rem"}}>Login Plataforma Ac boost</span><br />
                        <span className="ml-1" style={{fontSize: "0.85rem"}}>Acesse para iniciar seu cadastro no programa ou acompanhar sua evolução.</span>
                      </div>
                    </div>
                    <div className="row margin">
                      <div className="input-field col s12">
                        <input className="login-font-size" id="username" type="text"
                          onChange={handleFormInputChange('user')} value={form.user} />
                        <label htmlFor="username" className="center-align">E-mail</label>
                      </div>
                    </div>
                    <div className="row margin">
                      <div className="input-field col s12" style={{marginBottom: 0}}>
                        <input className="login-font-size" id="password" type="password"
                          onChange={handleFormInputChange('password')} value={form.password} />
                        <label htmlFor="password">Senha</label>
                      </div>
                      <div className="col s12" style={{marginTop: 0}}>
                          <a className="forgot-password-link" href={"/#/forgot-password"}>
                          Esqueceu sua senha?
                        </a>
                      </div>
                    </div>

                    <div className="row" >
                      <div className="input-field col s12" style={{marginBottom: 0}}>
                        {form.loading ? (
                          <div className="progress">
                            <div className="indeterminate"/>
                          </div>
                        ) : (
                            <>
                            <button
                              type="submit"
                              onClick={() => doLogin()}
                              className={"btn waves-effect waves-light border-radius-8 " +
                              "gradient-45deg-orange-acsp col s12 login-btn mt-2"}>
                              Login
                            </button>
                            {process.env.REACT_APP_PROGRAM_ACBOOST_LOGIN_CLOSED !== "true" &&
                              <div className="col s12" style={{padding: 0, fontSize: !matches && "1rem"}}>
                                <p style={{
                                  marginTop: "18px",
                                  marginBottom: "0",
                                  textAlign: "center",
                                  color: "rgb(22, 22, 22)",
                                  fontWeight: "bold",
                                  }}>
                                  Ainda não se inscreveu no Ac boost?&nbsp;
                                  <a className="new-user-link" href={"/#/new-user"}>
                                    Clique aqui e crie seu login
                                  </a>
                                </p>
                              </div>
                            }
                            </>
                          )}
                      </div>
                    </div>

                    <div className="row" style={{marginTop: 0}}>
                      <div className="input-field col s6 m6 l6">
                        <p className="margin medium-small"/>
                      </div>
                      <div className="input-field col s6 m6 l6">
                        <p className="margin right-align medium-small">{process.env.REACT_APP_VERSION}</p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row" style={{width: "100%", textAlign: "center"}}>
                <div className="col s12" style={{maxWidth: "100%", padding: "1rem 1rem 0 1rem"}}>
                  <Button
                    fullWidth
                    style={{color: "#d72425", fontSize: "0.8rem", maxWidth: "330px"}}
                    href="documents/P76_Acb23_Regulamento_Acboost_2023_VF.pdf"
                    target="_blank"
                    startIcon={<ChevronRight />}
                    >
                    Download do Regulamento
                  </Button>
                </div>
                <div className="col s12" style={{maxWidth: "100%", padding: "1rem 1rem 0 1rem"}}>
                  <Button
                    fullWidth
                    style={{color: "#d72425", fontSize: "0.8rem", maxWidth: "330px"}}
                    href="documents/Cronograma_AC_BOOST_23.pdf"
                    target="_blank"
                    startIcon={<ChevronRight />}
                    >
                    Download do Cronograma Ac boost
                  </Button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    </>
  );
}

export default Login;