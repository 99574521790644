import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import {actionPitchUpdate} from './pitch.providers';
import {SystemStatus, Steps} from '../../Common/Enums';
import FormInstructions from './FormInstructions';

CheckupLegalGovernance.propTypes = {
  successCallback: PropTypes.any,
  history: PropTypes.object,
  getPitchDetails: PropTypes.func.isRequired,
};

function CheckupLegalGovernance(props) {

  const dispatch = useDispatch();
  const {currentCompany} = useSelector(state => state.pitch);
  const [company, setCompany] = useState(null);
  const [legalDocs, setLegalDocs] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setCompany({...currentCompany});
    setLegalDocs(currentCompany.em_relacao_a_documentos_j ? currentCompany.em_relacao_a_documentos_j.split(',') : []);
  }, [currentCompany]);

  const save = saveMode => {
    setIsSaving(true);
    company.system_status = saveMode;

    dispatch(actionPitchUpdate(company, (success, message, updateResult) => {
      if (!success) {
        toast.info(message);
      } else {
        if (updateResult.changedRows === 0) {
          toast.info('Seus dados já estão salvos. Nenhuma informação foi alterada.');
        } else {
          toast.success('💾 Informações atualizadas com sucesso!');
          props.getPitchDetails();
        }
      }
      setIsSaving(false);
    }));
  };

  const handleCompanyUpdate = (field, value) => {
    setCompany({...company, [field]: value});
  };
  
  const isDisabled = () => {
    return company && (currentCompany.system_status === SystemStatus.finalizado || currentCompany.etapa_atual !== Steps.etapa6.value);
  };

  const handleLegalDocs = (event) => {
    setLegalDocs(event.target.value);
    handleCompanyUpdate('em_relacao_a_documentos_j', event.target.value);
  };

  const legalDocuments = [
    'Memorando de Entendimentos',
    'Contrato Social',
    'Acordo entre Sócios',
    'Acordo ou Cláusula de Não-Competição',
    'Acordo ou Cláusula de Confidencialidade',
    'Termos de Uso',
    'Termo de Conduta',
    'Registro de Marca e/ou Patente',
    'Contrato de Mútuo',
    'Contrato de Vesting',
  ];

  return company && (
    <div className="mauticform_wrapper" style={{padding: "0.8rem"}}>

      <FormInstructions materialIconName="account_balance">
        Apesar desse título longo, nossa intenção nessa etapa é muito simples: entender como estão as questões legais da startup, como tratamento de informações de clientes, nível de formalização da sociedade e temas relacionados. De novo, seja o mais transparente possível, para que possamos prever na sua jornada o apoio necessário para resolver qualquer questão.
      </FormInstructions>

      <form autoComplete="false" method="post" encType="multipart/form-data">
        
        <div className="mauticform-innerform">

          <div className="mauticform-page-wrapper mauticform-page-1">

            <div className="mauticform-row mauticform-freetext mauticform-field-1">
              <h3 className="mauticform-label">
                Checkup Jurídico e Governança
              </h3>
              <div className="mauticform-freetext">
                Preencha com atenção todos os campos.
              </div>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Em relação a documentos jurídicos e contratos. Assinale quais vocês possuem:</label>
              <Select
                multiple
                fullWidth
                value={legalDocs}
                onChange={!isDisabled() && handleLegalDocs}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                >
                {legalDocuments.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox disabled={isDisabled()} checked={legalDocs.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">A Propriedade Intelectual da Startup está bem estabelecida entre os sócios?</label>
              <Select
                fullWidth
                value={company.a_propriedade_intelectual}
                onChange={e => handleCompanyUpdate('a_propriedade_intelectual', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Ainda não conversamos sobre isso">Ainda não conversamos sobre isso</MenuItem>
                <MenuItem value="Temos alguma ideia">Temos alguma ideia</MenuItem>
                <MenuItem value="Conversamos sempre">Conversamos sempre</MenuItem>
                <MenuItem value="Existe um acordo formalizado">Existe um acordo formalizado</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Foram definidas as regras sobre o tratamento de informações e outros ativos confidenciais?</label>
              <Select
                fullWidth
                value={company.foram_definidas_as_regras}
                onChange={e => handleCompanyUpdate('foram_definidas_as_regras', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Ainda não conversamos sobre isso">Ainda não conversamos sobre isso</MenuItem>
                <MenuItem value="Temos alguma ideia">Temos alguma ideia</MenuItem>
                <MenuItem value="Conversamos sempre">Conversamos sempre</MenuItem>
                <MenuItem value="Existe um acordo formalizado">Existe um acordo formalizado</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Sobre termos de uso, existem regras claras?</label>
              <Select
                fullWidth
                value={company.sobre_termos_de_uso_exist}
                onChange={e => handleCompanyUpdate('sobre_termos_de_uso_exist', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Ainda não conversamos sobre isso">Ainda não conversamos sobre isso</MenuItem>
                <MenuItem value="Temos alguma ideia">Temos alguma ideia</MenuItem>
                <MenuItem value="Conversamos sempre">Conversamos sempre</MenuItem>
                <MenuItem value="Existe um acordo formalizado">Existe um acordo formalizado</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">As condições e regras de não competição/concorrência foram discutidas?</label>
              <Select
                fullWidth
                value={company.as_condicoes_e_regras_de}
                onChange={e => handleCompanyUpdate('as_condicoes_e_regras_de', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Ainda não conversamos sobre isso">Ainda não conversamos sobre isso</MenuItem>
                <MenuItem value="Temos alguma ideia">Temos alguma ideia</MenuItem>
                <MenuItem value="Conversamos sempre">Conversamos sempre</MenuItem>
                <MenuItem value="Existe um acordo formalizado">Existe um acordo formalizado</MenuItem>

              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Já foi discutido o que acontecerá caso o produto, mercado, tecnologia ou propósito da empresa precise ser alterado?</label>
              <Select
                fullWidth
                value={company.ja_foi_discutido_o_que_ac}
                onChange={e => handleCompanyUpdate('ja_foi_discutido_o_que_ac', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Ainda não conversamos sobre isso">Ainda não conversamos sobre isso</MenuItem>
                <MenuItem value="Temos alguma ideia">Temos alguma ideia</MenuItem>
                <MenuItem value="Conversamos sempre">Conversamos sempre</MenuItem>
                <MenuItem value="Existe um acordo formalizado">Existe um acordo formalizado</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Está bem claro para todos quais serão as habilidades e tempo de dedicação aportados por cada uma das partes?</label>
              <Select
                fullWidth
                value={company.esta_bem_claro_para_todos}
                onChange={e => handleCompanyUpdate('esta_bem_claro_para_todos', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Ainda não conversamos sobre isso">Ainda não conversamos sobre isso</MenuItem>
                <MenuItem value="Temos alguma ideia">Temos alguma ideia</MenuItem>
                <MenuItem value="Conversamos sempre">Conversamos sempre</MenuItem>
                <MenuItem value="Existe um acordo formalizado">Existe um acordo formalizado</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">A capacidade de aportes, necessidade de retiradas, remuneração mensal, prazos e valores já foram tópicos discutidos entre os sócios?</label>
              <Select
                fullWidth
                value={company.a_capacidade_de_aportes_n}
                onChange={e => handleCompanyUpdate('a_capacidade_de_aportes_n', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Ainda não conversamos sobre isso">Ainda não conversamos sobre isso</MenuItem>
                <MenuItem value="Temos alguma ideia">Temos alguma ideia</MenuItem>
                <MenuItem value="Conversamos sempre">Conversamos sempre</MenuItem>
                <MenuItem value="Existe um acordo formalizado">Existe um acordo formalizado</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Papéis e responsabilidades dos sócios na empresa estão claras e definidas?</label>
              <Select
                fullWidth
                value={company.papeis_e_responsabilidade}
                onChange={e => handleCompanyUpdate('papeis_e_responsabilidade', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Ainda não conversamos sobre isso">Ainda não conversamos sobre isso</MenuItem>
                <MenuItem value="Temos alguma ideia">Temos alguma ideia</MenuItem>
                <MenuItem value="Conversamos sempre">Conversamos sempre</MenuItem>
                <MenuItem value="Existe um acordo formalizado">Existe um acordo formalizado</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-button-wrapper mauticform-field-37">
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0 0 1.5rem"}}>

                <>
                {!isDisabled() &&
                <button 
                  type="button" 
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.rascunho)}
                  disabled={isDisabled()}
                >
                  Salvar Rascunho
                </button>
                }

                <div style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                  {isSaving && <CircularProgress />}
                  {(isDisabled() && !isSaving) && <>Etapa Finalizada 🎉</>}
                </div>

                {!isDisabled() &&
                <button
                  type="button"
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.finalizado)}
                  disabled={isDisabled()}
                >
                  Finalizar Etapa
                </button>
                }
                </>

              </div>
            </div>

          </div>
        </div>

      </form>
    </div>
  );
}

export default CheckupLegalGovernance;
