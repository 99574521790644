import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {
  TextField, Select, MenuItem, CircularProgress
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {toast} from 'react-toastify';
import {DateTime} from 'luxon';
import NumberFormat from 'react-number-format';
import {actionPitchUpdate, actionGetInfoCEP} from './pitch.providers';
import {SystemStatus, Steps} from '../../Common/Enums';
import FormInstructions from './FormInstructions';
import {toStateName} from '../../utils/helper';

Entrepreneur.propTypes = {
  successCallback: PropTypes.any,
  history: PropTypes.object,
  getPitchDetails: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function Entrepreneur(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const dispatch = useDispatch();
  const {currentCompany} = useSelector(state => state.pitch);
  const [company, setCompany] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [cepData, setCepData] = useState(['', {}]);
  const [cepPartnerStatus, setCepPartnerStatus] = useState({});

  useEffect(() => {
    setCompany({...currentCompany});
  }, [currentCompany]);

  const save = saveMode => {
    setIsSaving(true);
    company.system_status = saveMode;

    dispatch(actionPitchUpdate(company, (success, message, updateResult) => {
      if (!success) {
        toast.info(message);
      } else {
        if (updateResult.changedRows === 0) {
          toast.info('Seus dados já estão salvos. Nenhuma informação foi alterada.');
        } else {
          toast.success('💾 Informações atualizadas com sucesso!');
          props.getPitchDetails();
        }
      }
      setIsSaving(false);
    }));
  };

  const getInfoCEP = (cep, field) => {
    setCepPartnerStatus({[field]: {message: 'Aguarde! Consultando CEP...', color: "darkgrey"}});
    dispatch(actionGetInfoCEP(cep, field, (success, data) => {
      if (success) {
        setCepData(data);
        setCepPartnerStatus({});
      } else {
        setCepData([field, {}]);
        if (field.includes('zipcode')) {
          setCepPartnerStatus({[field]: {message: '(não encontrado)', color: "red"}});
        }
      }
    }));
  };

  const handleCompanyUpdate = (field, value) => {
    setCompany({...company, [field]: value});

    if ((field.includes('zipcode')) && value.length === 8) {
      if ((field.includes('zipcode') && value !== company[field])) {
        getInfoCEP(value, field);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(cepData[1]).length > 0) {
      let address1, estado, city;
      switch (cepData[0]) {
        case 'zipcode':
          address1 = 'address1';
          city = 'city';
          estado = 'estado';
          break;
        case 'zipcode_socio2':
          address1 = 'address1_socio2';
          city = 'city_socio2';
          estado = 'estado_socio2';
          break;
        case 'zipcode_socio3':
          address1 = 'address1_socio3';
          city = 'city_socio3';
          estado = 'estado_socio3';
          break;
        case 'zipcode_socio4':
          address1 = 'address1_socio4';
          city = 'city_socio4';
          estado = 'estado_socio4';
          break;
        case 'zipcode_socio5':
          address1 = 'address1_socio5';
          city = 'city_socio5';
          estado = 'estado_socio5';
          break;
        case 'zipcode_socio6':
          address1 = 'address1_socio6';
          city = 'city_socio6';
          estado = 'estado_socio6';
          break;
        default:
          return;
      }
      setCompany({
        ...company,
        [address1]: `${cepData[1]['logradouro']}, (informar o número/complemento) - ${cepData[1]['bairro']}`,
        [estado]: toStateName(cepData[1]['uf']),
        [city]: cepData[1]['localidade'],
      });
      setCepPartnerStatus({});
    }
  }, [cepData]);

  const isDisabled = () => {
    return company && (currentCompany.system_status === SystemStatus.finalizado || currentCompany.etapa_atual !== Steps.etapa2.value);
  };

  return company && (
    <div className="mauticform_wrapper" style={{padding: "0.8rem"}}>

      <FormInstructions materialIconName="group">
        Ótimo!<br />
        O primeiro passo rumo ao boost já foi dado!<br />
        Nesta etapa, queremos entender melhor como é a formado seu time, o know-how dos fundadores, suas experiências e dedicação na startup. Para isso, antes de finalizar esta etapa garanta que tenha preenchido adequadamente os dados de número de sócios que sua startup possui.
      </FormInstructions>

      <form autoComplete="false" method="post" encType="multipart/form-data">

        <div className="mauticform-innerform">

          <div className="mauticform-page-wrapper mauticform-page-1">

            <div className="mauticform-row mauticform-freetext mauticform-field-1">
              <h3 className="mauticform-label">
                Dados do Empreendedor e Equipe
              </h3>
              <div className="mauticform-freetext">
                Preencha com atenção todos os campos.
              </div>
            </div>

            <div className={classes.root}>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className={classes.heading}>
                    <div style={{display: "flex", alignItems: "center", width: "20rem"}}>
                      <i className="material-icons orange-acsp-dark">album</i>&nbsp;
                      Dados Gerais da Equipe
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails style={{display: "block"}}>
                  <div>
                    <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
                      <label className="mauticform-label">Habilidades e capacidades: empresarial e gerencial:</label>
                      <Select
                        fullWidth
                        style={{maxWidth: "99%"}}
                        value={company.habilidadesecapacidades}
                        onChange={e => handleCompanyUpdate('habilidadesecapacidades', e.target.value)}
                        disabled={isDisabled()}
                      >
                        <MenuItem value="Nenhum dos membros da equipe têm estudado assuntos relacionados aos negócios">Nenhum dos membros da equipe têm estudado assuntos relacionados aos negócios</MenuItem>
                        <MenuItem value="Alguns dos membros da equipe têm estudado assuntos relacionados aos negócios, mas não tem experiência">Alguns dos membros da equipe têm estudado assuntos relacionados aos negócios, mas não tem experiência</MenuItem>
                        <MenuItem value="Alguns da equipe membros estudaram assuntos relacionados aos negócios e/ou tem experiência de trabalho como empregado sem atingir cargos gerenciais">Alguns da equipe membros estudaram assuntos relacionados aos negócios e/ou tem experiência de trabalho como empregado sem atingir cargos gerenciais</MenuItem>
                        <MenuItem value="Alguns dos membros da equipe têm estudado assuntos relacionados aos negócios e/ou tem alcançado um nível médio de cargos gerenciais como empregado">Alguns dos membros da equipe têm estudado assuntos relacionados aos negócios e/ou tem alcançado um nível médio de cargos gerenciais como empregado</MenuItem>
                        <MenuItem value="Alguns dos membros da equipe trabalharam como gerentes de alto nível">Alguns dos membros da equipe trabalharam como gerentes de alto nível</MenuItem>
                      </Select>
                    </div>

                    <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
                      <label className="mauticform-label">Quantos anos de experiência tem o sócio com maior know how no mercado que a startup pretende explorar?</label>
                      <Select
                        fullWidth
                        style={{maxWidth: "99%"}}
                        value={company.anosexperienciasociomaior}
                        onChange={e => handleCompanyUpdate('anosexperienciasociomaior', e.target.value)}
                        disabled={isDisabled()}
                      >
                        <MenuItem value="Menos de 2 anos">Menos de 2 anos</MenuItem>
                        <MenuItem value="Entre 2 e 5 anos">Entre 2 e 5 anos</MenuItem>
                        <MenuItem value="Entre 5 e 10 anos">Entre 5 e 10 anos</MenuItem>
                        <MenuItem value="Acima de 10 anos">Acima de 10 anos</MenuItem>
                      </Select>
                    </div>

                    <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
                      <label className="mauticform-label">Algum dos fundadores possui experiência empresarial anterior e concluiu em uma saída bem sucedida?</label>
                      <Select
                        fullWidth
                        style={{maxWidth: "99%"}}
                        value={company.fundadorespossuiexperienc}
                        onChange={e => handleCompanyUpdate('fundadorespossuiexperienc', e.target.value)}
                        disabled={isDisabled()}
                      >
                        <MenuItem value="Não, todos os fundadores estão em sua primeira experiência empresarial">Não, todos os fundadores estão em sua primeira experiência empresarial</MenuItem>
                        <MenuItem value="Alguns dos fundadores trabalharam em ambiente empresarial, mas nunca tinham começado sua própria empresa">Alguns dos fundadores trabalharam em ambiente empresarial, mas nunca tinham começado sua própria empresa</MenuItem>
                        <MenuItem value="Alguns dos fundadores têm experiência empresarial anterior">Alguns dos fundadores têm experiência empresarial anterior</MenuItem>
                        <MenuItem value="Alguns dos fundadores são empreendedores em série e possuem pelo menos uma saída bem sucedida">Alguns dos fundadores são empreendedores em série e possuem pelo menos uma saída bem sucedida</MenuItem>
                        <MenuItem value="Todos os fundadores são empreendedores em série e possuem pelo menos uma saída bem sucedida">Todos os fundadores são empreendedores em série e possuem pelo menos uma saída bem sucedida</MenuItem>
                      </Select>
                    </div>

                    <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
                      <label className="mauticform-label">Neste momento da startup, os sócios envolvidos diretamente na operação.</label>
                      <Select
                        fullWidth
                        style={{maxWidth: "99%"}}
                        value={company.momentodastartupsociosgan}
                        onChange={e => handleCompanyUpdate('momentodastartupsociosgan', e.target.value)}
                        disabled={isDisabled()}
                      >
                        <MenuItem value="Não são remunerados">Não são remunerados</MenuItem>
                        <MenuItem value="São remunerados em valores abaixo do mercado">São remunerados em valores abaixo do mercado</MenuItem>
                        <MenuItem value="São remunerados em valores de acordo com o mercado">São remunerados em valores de acordo com o mercado</MenuItem>
                      </Select>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* Partner 1 to 6 */}
              {
                [
                  {firstname: 'firstname', email: 'email', cpf: 'cpf', birthdaydate: 'birthdaydate', zipcode: 'zipcode', address1: 'address1', city: 'city', estado: 'estado', phone: 'phone', perfilpredominantecomoemp: 'perfilpredominantecomoemp', dedicacaonastartup: 'dedicacaonastartup', qualsuaformacao: 'qualsuaformacao', instagram: 'instagram', linkedin: 'linkedin'
                  },
                  {firstname: 'firstname_socio2', email: 'email_socio2', cpf: 'cpf_socio2', birthdaydate: 'birthdaydate_socio2', zipcode: 'zipcode_socio2', address1: 'address1_socio2', city: 'city_socio2', estado: 'estado_socio2', phone: 'phone_socio2', perfilpredominantecomoemp: 'perfil_pred_socio_2', dedicacaonastartup: 'dedicacaonastartup_2', qualsuaformacao: 'qualsuaformacao_socio2', instagram: 'instagram_socio2', linkedin: 'linkedin_socio2'
                  },
                  {firstname: 'firstname_socio3', email: 'email_socio3', cpf: 'cpf_socio3', birthdaydate: 'birthdaydate_socio3', zipcode: 'zipcode_socio3', address1: 'address1_socio3', city: 'city_socio3', estado: 'estado_socio3', phone: 'phone_socio3', perfilpredominantecomoemp: 'perfil_pred_socio_3', dedicacaonastartup: 'dedicacaonastartup_3', qualsuaformacao: 'qualsuaformacao_socio3', instagram: 'instagram_socio3', linkedin: 'linkedin_socio3'
                  },
                  {firstname: 'firstname_socio4', email: 'email_socio4', cpf: 'cpf_socio4', birthdaydate: 'birthdaydate_socio4', zipcode: 'zipcode_socio4', address1: 'address1_socio4', city: 'city_socio4', estado: 'estado_socio4', phone: 'phone_socio4', perfilpredominantecomoemp: 'perfil_pred_socio_4', dedicacaonastartup: 'dedicacaonastartup_4', qualsuaformacao: 'qualsuaformacao_socio4', instagram: 'instagram_socio4', linkedin: 'linkedin_socio4'
                  },
                  {firstname: 'firstname_socio5', email: 'email_socio5', cpf: 'cpf_socio5', birthdaydate: 'birthdaydate_socio5', zipcode: 'zipcode_socio5', address1: 'address1_socio5', city: 'city_socio5', estado: 'estado_socio5', phone: 'phone_socio5', perfilpredominantecomoemp: 'perfil_pred_socio_5', dedicacaonastartup: 'dedicacaonastartup_5', qualsuaformacao: 'qualsuaformacao_socio5', instagram: 'instagram_socio5', linkedin: 'linkedin_socio5'
                  },
                  {firstname: 'firstname_socio6', email: 'email_socio6', cpf: 'cpf_socio6', birthdaydate: 'birthdaydate_socio6', zipcode: 'zipcode_socio6', address1: 'address1_socio6', city: 'city_socio6', estado: 'estado_socio6', phone: 'phone_socio6', perfilpredominantecomoemp: 'perfil_pred_socio_6', dedicacaonastartup: 'dedicacaonastartup_6', qualsuaformacao: 'qualsuaformacao_socio6', instagram: 'instagram_socio6', linkedin: 'linkedin_socio6'
                  }
                ].map((partner, index) => {
                  return (
                  <Accordion key={`panel${index + 2}`} expanded={expanded === `panel${index + 2}`} onChange={handleChange(`panel${index + 2}`)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 2}bh-content`}
                      id={`panel${index + 2}bh-header`}
                    >
                      <div className={classes.heading}>
                        <div style={{display: "flex", alignItems: "center", width: "20rem"}}>
                          <i className="material-icons orange-acsp-dark">album</i>&nbsp;
                          Sócio {index + 1}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={{display: "block"}}>
                      <div>
                        <div className="mauticform-row mauticform-text mauticform-field-2 mauticform-required">
                          <label className="mauticform-label">Nome Completo</label>
                          <span className="mauticform-helpmessage">Digite seu nome completo</span>
                          <input
                            className="mauticform-input"
                            type="text"
                            value={company[partner.firstname]}
                            maxLength="191"
                            placeholder="Obrigatório ser um sócio da empresa"
                            onChange={e => handleCompanyUpdate(partner.firstname, e.target.value)}
                            disabled={isDisabled()}
                          />
                        </div>

                        <div className="mauticform-row mauticform-email mauticform-field-3 mauticform-required">
                          <label className="mauticform-label">E-mail</label>
                          <input value={company[partner.email]} className="mauticform-input" type="email" onChange={e => handleCompanyUpdate(partner.email, e.target.value)} disabled={index === 0 ? true : isDisabled()} maxLength="191"/>
                        </div>

                        <div className="mauticform-row mauticform-number mauticform-field-4 mauticform-required">
                          <label className="mauticform-label">CPF</label>
                          <NumberFormat
                            InputProps={{disableUnderline: true}}
                            fullWidth
                            customInput={TextField}
                            value={company[partner.cpf]}
                            onValueChange={e => handleCompanyUpdate(partner.cpf, e.value)}
                            format={"###.###.###-##"}
                            disabled={isDisabled()}
                          />
                        </div>

                        <div className="mauticform-row mauticform-date mauticform-field-5 mauticform-required">
                          <label className="mauticform-label">Data de Nascimento</label>
                          <input value={DateTime.fromISO(company[partner.birthdaydate]).toFormat('yyyy-MM-dd')} className="mauticform-input" type="date" onChange={e => handleCompanyUpdate(partner.birthdaydate, DateTime.fromISO(e.target.value).toFormat('yyyy-MM-dd'))} disabled={isDisabled()}/>
                        </div>

                        <div className="mauticform-row mauticform-number mauticform-field-7 mauticform-required">
                          <label className="mauticform-label">
                            CEP&nbsp;
                            <span style={{fontSize: "0.8rem", color: cepPartnerStatus[partner.zipcode] && cepPartnerStatus[partner.zipcode].color}}>
                              {cepPartnerStatus[partner.zipcode] && cepPartnerStatus[partner.zipcode].message}
                            </span>
                          </label>
                          <NumberFormat
                            InputProps={{disableUnderline: true}}
                            fullWidth
                            customInput={TextField}
                            value={company[partner.zipcode]}
                            onValueChange={e => handleCompanyUpdate(partner.zipcode, e.value)}
                            format={"#####-###"}
                            disabled={isDisabled()}
                          />
                        </div>

                        <div className="mauticform-row mauticform-text mauticform-field-6 mauticform-required">
                          <label className="mauticform-label">Endereço</label>
                          <input value={company[partner.address1]} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate(partner.address1, e.target.value)} disabled={isDisabled()} maxLength="191"/>
                        </div>

                        <div className="mauticform-row mauticform-text mauticform-field-9 mauticform-required">
                          <label className="mauticform-label">Cidade</label>
                          <input value={company[partner.city]} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate(partner.city, e.target.value)} disabled={isDisabled()} maxLength="191"/>
                        </div>

                        <div className="mauticform-row mauticform-select mauticform-field-8 mauticform-required">
                          <label className="mauticform-label">Estado</label>
                          <Select
                            fullWidth
                            value={company[partner.estado]}
                            onChange={e => handleCompanyUpdate(partner.estado, e.target.value)}
                            disabled={isDisabled()}
                          >
                            <MenuItem value={null}></MenuItem>
                            <MenuItem value="Acre">AC</MenuItem>
                            <MenuItem value="Alagoas">AL</MenuItem>
                            <MenuItem value="Amapá">AP</MenuItem>
                            <MenuItem value="Amazonas">AM</MenuItem>
                            <MenuItem value="Bahia">BA</MenuItem>
                            <MenuItem value="Ceará">CE</MenuItem>
                            <MenuItem value="Distrito Federal">DF</MenuItem>
                            <MenuItem value="Espírito Santo">ES</MenuItem>
                            <MenuItem value="Goiás">GO</MenuItem>
                            <MenuItem value="Maranhão">MA</MenuItem>
                            <MenuItem value="Mato Grosso">MT</MenuItem>
                            <MenuItem value="Mato Grosso do Sul">MS</MenuItem>
                            <MenuItem value="Minas Gerais">MG</MenuItem>
                            <MenuItem value="Pará">PA</MenuItem>
                            <MenuItem value="Paraíba">PB</MenuItem>
                            <MenuItem value="Paraná">PR</MenuItem>
                            <MenuItem value="Pernambuco">PE</MenuItem>
                            <MenuItem value="Piauí">PI</MenuItem>
                            <MenuItem value="Rio de Janeiro">RJ</MenuItem>
                            <MenuItem value="Rio Grande do Norte">RN</MenuItem>
                            <MenuItem value="Rio Grande do Sul">RS</MenuItem>
                            <MenuItem value="Rondônia">RO</MenuItem>
                            <MenuItem value="Roraima">RR</MenuItem>
                            <MenuItem value="Santa Catarina">SC</MenuItem>
                            <MenuItem value="São Paulo">SP</MenuItem>
                            <MenuItem value="Sergipe">SE</MenuItem>
                            <MenuItem value="Tocantins">TO</MenuItem>
                          </Select>
                        </div>

                        <div className="mauticform-row mauticform-number mauticform-field-10 mauticform-required">
                          <label className="mauticform-label">Celular com DDD</label>
                          <NumberFormat
                            InputProps={{disableUnderline: true}}
                            fullWidth
                            customInput={TextField}
                            value={company[partner.phone]}
                            onValueChange={e => handleCompanyUpdate(partner.phone, e.value)}
                            format={company[partner.phone] && company[partner.phone].length < 11 ? "(##) ####-#####" : "(##) #####-####"}
                            disabled={isDisabled()}
                          />
                        </div>

                        <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
                          <label className="mauticform-label">Qual o seu perfil predominante como empreendedor?</label>
                          <Select
                            fullWidth
                            value={company[partner.perfilpredominantecomoemp]}
                            onChange={e => handleCompanyUpdate(partner.perfilpredominantecomoemp, e.target.value)}
                            disabled={isDisabled()}
                          >
                            <MenuItem value={null}></MenuItem>
                            <MenuItem value="Desenvolvedor (web, mobile, front e back-end)">Desenvolvedor (web, mobile, front e back-end)</MenuItem>
                            <MenuItem value="Design, mais focado na usabilidade de produtos e experiência de uso, além de web design ou design em geral">Design, mais focado na usabilidade de produtos e experiência de uso, além de web design ou design em geral</MenuItem>
                            <MenuItem value="Vendas, BizDev, Customer">Vendas, BizDev, Customer</MenuItem>
                            <MenuItem value="Marketing digital, Ads e growth hacking">Marketing digital, Ads e growth hacking</MenuItem>
                            <MenuItem value="Finanças">Finanças</MenuItem>
                            <MenuItem value="Governança">Governança</MenuItem>
                            <MenuItem value="Gestão de pessoas">Gestão de pessoas</MenuItem>
                            <MenuItem value="Outro">Outro</MenuItem>
                          </Select>
                        </div>

                        <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
                          <label className="mauticform-label">Qual a dedicação na Startup?</label>
                          <Select
                            fullWidth
                            value={company[partner.dedicacaonastartup]}
                            onChange={e => handleCompanyUpdate(partner.dedicacaonastartup, e.target.value)}
                            disabled={isDisabled()}
                          >
                            <MenuItem value={null}></MenuItem>
                            <MenuItem value="Parcialmente - atua simultaneamente em outros projetos">Parcialmente - atua simultaneamente em outros projetos</MenuItem>
                            <MenuItem value="Integralmente - atua única e exclusivamente na startup">Integralmente - atua única e exclusivamente na startup</MenuItem>
                            <MenuItem value="Muito pouca ou aleatória">Muito pouca ou aleatória</MenuItem>
                            <MenuItem value="Pretende se dedicar exclusivamente quando a empresa estiver totalmente operacional">Pretende se dedicar exclusivamente quando a empresa estiver totalmente operacional</MenuItem>
                          </Select>
                        </div>

                        <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
                          <label className="mauticform-label">Qual sua formação?</label>
                          <Select
                            fullWidth
                            value={company[partner.qualsuaformacao]}
                            onChange={e => handleCompanyUpdate(partner.qualsuaformacao, e.target.value)}
                            disabled={isDisabled()}
                          >
                            <MenuItem value={null}></MenuItem>
                            <MenuItem value="Ensino Médio">Ensino Médio</MenuItem>
                            <MenuItem value="Cursando Graduação">Cursando Graduação</MenuItem>
                            <MenuItem value="Graduação Completa">Graduação Completa</MenuItem>
                            <MenuItem value="Pós-Graduação, MBA, Mestrado ou Equivalente">Pós-Graduação, MBA, Mestrado ou Equivalente</MenuItem>
                            <MenuItem value="Doutorado">Doutorado</MenuItem>
                          </Select>
                        </div>

                        <div className="mauticform-row mauticform-freetext mauticform-field-11">
                          <h3 className="mauticform-label">
                            Mídias Sociais:
                          </h3>
                          <div className="mauticform-freetext"></div>
                        </div>

                        <div className="mauticform-row mauticform-text mauticform-field-12">
                          <label className="mauticform-label">Instagram</label>
                          <input value={company[partner.instagram]} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate(partner.instagram, e.target.value)} disabled={isDisabled()} maxLength="120"/>
                        </div>

                        <div className="mauticform-row mauticform-text mauticform-field-13">
                          <label className="mauticform-label">Linkedin</label>
                          <input value={company[partner.linkedin]} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate(partner.linkedin, e.target.value)} disabled={isDisabled()} maxLength="120"/>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  );
                })
              }

            </div>

            <div className="mauticform-row mauticform-button-wrapper mauticform-field-37">
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "2rem 0 1.5rem"}}>

                <>
                {!isDisabled() &&
                <button
                  type="button"
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.rascunho)}
                  disabled={isDisabled()}
                >
                  Salvar Rascunho
                </button>
                }

                <div style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                  {isSaving && <CircularProgress />}
                  {(isDisabled() && !isSaving) && <>Etapa Finalizada 🎉</>}
                </div>

                {!isDisabled() &&
                <button
                  type="button"
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.finalizado)}
                  disabled={isDisabled()}
                >
                  Finalizar Etapa
                </button>
                }
                </>

              </div>
            </div>

          </div>
        </div>

      </form>
    </div>
  );
}

export default Entrepreneur;
