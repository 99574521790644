export const appConstants = {
  //COMPANIES
  COMPANIES_SEARCH_LIST: 'COMPANIES_SEARCH_LIST',
  COMPANY_LOAD_DETAILS: 'COMPANY_LOAD_DETAILS',

  //PRODUCTS
  PRODUCTS_SEARCH_LIST: 'PRODUCTS_SEARCH_LIST',
  PRODUCT_LOAD_DETAILS: 'PRODUCT_LOAD_DETAILS',

  //ORDERS
  ORDERS_SEARCH_LIST: 'ORDERS_SEARCH_LIST',
  ORDER_LOAD_DETAILS: 'ORDER_LOAD_DETAILS',

  //CHARGES
  CHARGES_SEARCH_LIST: 'CHARGES_SEARCH_LIST',
  UNCHARGED_ORDERS_LIST: 'UNCHARGED_ORDERS_LIST',
  CHARGE_LOAD_DETAILS: 'CHARGE_LOAD_DETAILS',

  // ADMS
  ADMS_LIST: 'ADMS_LIST',
};
