import React, { useState } from 'react';
import queryString from "query-string";
import { useDispatch } from 'react-redux';
import { actionResetPassword } from "./login.providers";
import { toast } from 'react-toastify';
import PropTypes from "prop-types";

ResetPassword.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

function ResetPassword(props) {

  const urlParams = queryString.parse(props.location.search);
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handlePasswordConfirmChange = event => {
    setConfirmPassword(event.target.value);
  };

  const doForgotPassword = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.info('Senha não confere');
      return;
    }
    setLoading(true);
    dispatch(
        actionResetPassword(urlParams.token, password, (success, message) => {
        setLoading(false);
        if (success) {
          toast.success('🎉 Senha alterada com sucesso!');
          props.history.push('/');
        } else {
          toast.info(message);
        }
      })
    );
  };

  return (
    <div id="login-page" className="login-bg blank-page">
      <h5 className="login-font-box" style={{margin: 0, padding: "1rem"}}>Ac boost</h5>
      <div style={{marginBottom: "1.5rem"}}></div>
      <div className="row" style={{display: "flex", height: "60vh", alignItems: "center"}}>
        <div className="col s12">
          <div className="container"><div className="row center-card">
            <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
              <form className="login-form">
                <div className="row">
                  <div className="input-field col s12">
                    <h5 className="ml-4">Redefinir Senha</h5>
                  </div>
                </div>
                <div className="row margin">
                  <div className="input-field col s12">
                    <i className="material-icons prefix pt-2 orange-acsp-light">lock</i>
                    <input
                      className="login-font-size"
                      id="password"
                      type="password"
                      onChange={handlePasswordChange}
                      value={password}
                    />
                    <label htmlFor="username" className="center-align">Senha</label>
                  </div>
                </div>
                <div className="row margin">
                  <div className="input-field col s12">
                    <i className="material-icons prefix pt-2 orange-acsp-light">lock</i>
                    <input 
                      className="login-font-size"
                      id="password"
                      type="password"
                      onChange={handlePasswordConfirmChange}
                      value={confirmPassword} 
                    />
                    <label htmlFor="username" className="center-align">Confirme a senha</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12">
                    {loading ? (
                      <div className="progress">
                        <div className="indeterminate"></div>
                      </div>
                    ) : (
                      <button
                        onClick={doForgotPassword}
                        className="btn waves-effect waves-light border-radius-8 gradient-45deg-orange-acsp normal-btn col s12">
                        Redefinir Senha
                      </button>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s6 m6 l6">
                    <p className="margin medium-small"/>
                  </div>
                  <div className="input-field col s6 m6 l6">
                    <p className="margin right-align medium-small">{process.env.REACT_APP_VERSION}</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;