import React from 'react';
import PropTypes from "prop-types";
import {clearStorage} from "../Core/localStorage";

Menu.propTypes = {
  selected: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

function Menu(props) {

  const logout = () => {
    clearStorage();
    props.history.push("/");
  };

  return (
    <aside className="sidenav-main nav-collapsible sidenav-light sidenav-active-square nav-collapsed">
      <div className="brand-sidebar background-black">
        <h1 className="logo-wrapper">
          <a>
            <img width="88px" style={{paddingTop: "24px", paddingLeft: "6px"}}
              src="../../../app-assets/images/logo/pateo76_logo_branco.png" alt=""
            />
          </a>
          <a className="navbar-toggler">
            <i style={{ cursor: 'pointer' }} className="material-icons">radio_button_unchecked</i>
          </a>
        </h1>
      </div>
      <ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed white-menu background-black"
          id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion">
        <li className={props.selected === "pitch" ? "active" : ""}>
          <a
              style={{ cursor: 'pointer' }}
              onClick={() => props.history.push(`pitch`)}
              className={props.selected === "pitch" ? "collapsible-body active white-text" : "collapsible-body"}>
            <i className="material-icons">description</i>
            <span>Cadastro Ac boost</span>
          </a>
        </li>
        <li className={props.selected === "mystartup" ? "active" : ""}>
          <a
              style={{ cursor: 'pointer' }}
              onClick={() => props.history.push(`mystartup`)}
              className={props.selected === "mystartup" ? "collapsible-body active white-text" : "collapsible-body"}>
            <i className="material-icons">person</i>
            <span>Minha Startup</span>
          </a>
        </li>
        <li className={props.selected === "home" ? "active" : ""}>
          <a
              style={{ cursor: 'pointer' }}
              onClick={() => props.history.push(`home`)}
              className={props.selected === "home" ? "collapsible-body active white-text" : "collapsible-body"}>
            <i className="material-icons">info_outlined</i>
            <span>Informações Ac boost</span>
          </a>
        </li>
        <hr/>
        <li>
          <a
              style={{ cursor: 'pointer' }}
              onClick={logout}
              className="collapsible-body">
            <i className="material-icons">exit_to_app</i>
            <span>Sair</span>
          </a>
        </li>
        <div style={{ marginRight: 215 }} className="input-field col s6 m6 l6">
          <li className="navigation-header">
            <p className="margin medium-small">{process.env.REACT_APP_VERSION}</p></li>
        </div>
      </ul>

      <div
        className="navigation-background">
      </div>
      <a
        className={"sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-effect " +
        "waves-light hide-on-large-only"}
        data-target="slide-out">
        <i className="material-icons">menu</i>
      </a>
    </aside>
  );
}

export default Menu;