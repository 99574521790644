import { reducerHome } from '../Pages/Home/home.providers';
import { reducerPitch } from '../Pages/Pitch/pitch.providers';

import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

export const rootReducer = combineReducers({
  home: reducerHome,
  pitch: reducerPitch,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
