import axios from '../../Core/axios-instance';
import { coreConstants } from '../../Core/core.constants';
import { handleResponseError } from '../../Common/Validations';

//STATES

const PITCH_STATE = {
  currentCompany: null,
};

//ACTIONS

export const actionPitchDetails = (callback) => async dispatch => {
  try {
    const response = await axios.get(`/pitch/company`);
    dispatch({ type: coreConstants.PITCH_DETAILS, payload: response.data.data });
    callback && callback(response.data.success, response.data.rows, response.data.message);
  } catch (e) {
    handleResponseError(e, callback);
  }
};

export const actionPitchUpdate = (body, callback) => async () => {
  try {
    const response = await axios.patch(`/pitch/company`, body);
    callback && callback(response.data.success, response.data.message, response.data.updateResult);
  } catch (e) {
    handleResponseError(e, callback);
  }
};

export const actionGetInfoCEP = (cep, field, callback) => async () => {
    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'default'
    };

    // eslint-disable-next-line no-undef
    fetch(`https://viacep.com.br/ws/${cep}/json/`, options)
    .then(response => {
      response.json()
      .then(data => {
        if (!data.erro) {
          callback && callback(true, [field, data]);
        } else {
          callback && callback(false, [field, {}]);
        }
      });
    })
    .catch (e => {
      console.error(`CEP API Error: ${e}`);
      handleResponseError(e, callback);
    });
  };

//REDUCER

export const reducerPitch = (state = PITCH_STATE, action) => {
  switch (action.type) {
    case coreConstants.PITCH_DETAILS:
      return { ...state, currentCompany: action.payload };
    default:
      return state;
  }
};
