import React, { Suspense } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import AuthenticatedRoute from './Core/routes';
import LoginPage from './Pages/Login/Login';
import Home from "./Pages/Home/Home";
import Pitch from "./Pages/Pitch/Pitch";
import MyStartup from "./Pages/Login/MyStartup";
import ForgotPassword from './Pages/Login/ForgotPassword';
import ResetPassword from './Pages/Login/ResetPassword';
import NewUser from './Pages/Login/NewUser';
import Thanks from "./Pages/Thanks/Thanks";
import store from './Core/redux.store';

const loading = () => <div className="loading-ui">Carregando</div>;

function App() {

  const authenticatedContent = () => {
    return (
      <>
        <Route path='/pitch' component={Pitch} />
        <Route path='/mystartup' component={MyStartup} />
        <Route path='/home' component={Home} />
        <Route path='/thank-you' component={Thanks} />
      </>
    );
  };

  return (
    <>
      <Provider store={store}>
        <Suspense fallback={loading()}>
          <HashRouter hashType="slash">
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/new-user" component={NewUser} />
              <AuthenticatedRoute
                path="/"
                caseAuth={authenticatedContent()}
                caseUnauth={<Redirect to={'/'} />}
              />
              <Redirect from='*' to='/' />
            </Switch>
          </HashRouter>
        </Suspense>
      </Provider>
    </>
  );
}


export default App;
