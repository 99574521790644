import axios from "../../Core/axios-instance";
import { appConstants } from '../../Common/AppConstants';
import { handleResponseError } from "../../Common/Validations";

//STATES

const ADMS_STATE = {
  admsList: []
};

//ACTIONS

export function actionList(callback) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/adms`);
      dispatch({ type: appConstants.ADMS_LIST, payload: response.data });
      callback && callback(true);
    } catch (e) {
      handleResponseError(e, callback);
    }
  };
}

export function actionCreateAdm(body, callback) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/adms`, body);
      dispatch({ type: appConstants.ADMS_LIST, payload: response.data });
      callback(true);
    } catch (e) {
      handleResponseError(e, callback);
    }
  };
}

export function actionChangePermission(currentId, permission, callback) {
  return async () => {
    try {
      await axios.patch(`/adms/${currentId}?permission=${permission}`);
      callback && callback(true);
    } catch (e) {
      handleResponseError(e, callback);
    }
  };
}

export function actionUpdateAdm(admId, body, callback) {
  return async () => {
    try {
      await axios.put(`/adms/${admId}`, body);
      callback && callback(true);
    } catch (e) {
      handleResponseError(e, callback);
    }
  };
}

//REDUCER

export function reducerHome(state = ADMS_STATE, action) {
  switch (action.type) {
    case appConstants.ADMS_LIST:
      return { ...state, admsList: action.payload };
    default:
      return state;
  }
}
