const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const handleResponseError = (e, callback) => {
  let message = "Oops an error has occured, try again";
  if (!e.response || !e.response.data) {
    callback(false, message);
    return;
  }
  const {data} = e.response;
  if (data.error && Array.isArray(data.error)) {
    const fields = data.error.map(e => {
      switch (e.msg) {
        case 'Invalid value':
          return e.param + ' (Valor inválido)';
        default:
          return e.param;
      }
    }).join("\n");
    callback(false, "Encontramos problemas nos seguintes campos:\n" + fields);
  } else {
    callback(false, JSON.stringify(data));
  }
};

export const formattedNumber = (number) => {
  return (number/100).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const formattedCnpj = cnpj => {
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};