import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Box, Button, Paper, Tooltip } from '@material-ui/core';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Menu from '../../Components/Menu';
import Header from '../../Components/Header';
import { actionPitchDetails } from '../Pitch/pitch.providers';
import { actionChangePassword, actionUploadLogo, actionUpdateLogo } from './login.providers';

UserEdit.propTypes = {
  successCallback: PropTypes.any,
  history: PropTypes.object,
};

const useStyles = makeStyles({
  root: {
    marginTop: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    minWidth: 275,
  },
  p0: {
    margin: "0.5rem 0 0 0",
    padding: 0,
  },
  steps: {
    marginTop: "1rem",
    fontSize: "1.14rem",
    fontWeight: "normal",
    color: "#000",
  },
  title: {
    fontSize: "1.7rem",
    fontWeight: "bold",
    color: "#000",
  },
  partners: {
    fontSize: "1.2rem",
    fontWeight: "normal",
    color: "#000",
  },
  partner: {
    padding: "0.5rem 0 0.5rem 0",
    color: "#000",
  },
});

function UserEdit(props) {

  const classes = useStyles();

  const matches = useMediaQuery('(min-width:993px)');

  const dispatch = useDispatch();
  const {currentCompany} = useSelector(state => state.pitch);
  const [isLoading, setIsLoading] = useState(false);
  const [oldPwd, setOldPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [newPwdCopy, setNewPwdCopy] = useState('');
  const [isPwdChanging, setIsPwdChanging] = useState(false);
  const [logoImage, setLogoImage] = useState();
  const [submitting, setSubmitting] = useState(false);

  const getPitchDetails = () => {
    setIsLoading(true);
    dispatch(actionPitchDetails((success, rows, message) => {
      if (!success) {
        toast.info(`Erro: ${message}`);
      }
      setIsLoading(false);
    }));
  };

  const changePassword = () => {
    if (newPwd.length < 4) {
      toast.error('🔐 Nova senha deve possuir pelo menos 4 caracteres!');
      return;
    }
    if (newPwd !== newPwdCopy) {
      toast.error('🔐 Senhas não conferem!');
      return;
    }
    setIsPwdChanging(true);
    dispatch(actionChangePassword(currentCompany.user_uuid, oldPwd, newPwd, (success, message) => {
      setIsPwdChanging(false);
      if (!success) {
        toast.error(message);
        return;
      }
      toast.success('🎉 Senha alterada com sucesso!');
      setOldPwd('');
      setNewPwd('');
      setNewPwdCopy('');
    }));
  };

  useEffect(() => {
    getPitchDetails();
  }, [dispatch]);

  useEffect(() => {
    currentCompany && setLogoImage(currentCompany.logo_url);
  }, [currentCompany]);

  const sendLogo = (logoFile) => {
    setSubmitting(true);
    dispatch(actionUploadLogo(logoFile, (success, fileName) => {
      if (success) {
        updateLogo(fileName);
      } else {
        if (fileName.includes('Logo não pode exceder 500Kb')) {
          toast.info('Oops, Tamanho do arquivo não pode exceder 500Kb. Tente novamente.');
        } else {
          toast.info('Oops, não foi possível salvar o logo da startup! Tente novamente.');
        }
        setSubmitting(false);
      }
    }));
  };

  const updateLogo = fileName => {
    dispatch(actionUpdateLogo(currentCompany.user_uuid, {logo_url: fileName}, (success, message) => {
      if (!success) {
        toast.info(message);
      } else {
        setLogoImage(fileName);
        toast.success("Logo da startup atualizado!");
      }
      setSubmitting(false);
    }));
  };

  const handleLogoImageChange = e => {
    if (e.target.files.length > 0) {
      sendLogo(e.target.files[0]);
    }
  };

  const renderLogo = () => {
    return (
      <>
      {logoImage
        ?
        <>
        <label htmlFor="upload-logo-button" style={{ cursor: "pointer" }}>
          <Tooltip title="Substituir o logo">
            <img style={{maxWidth: "126px", maxHeight: "126px", padding: "2px" }} src={logoImage} alt={""} />
          </Tooltip>
        </label>
        </>
        :
        <>
        <div style={{ padding: 0, margin: 0, width: "128px", height: "128px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <div>
            <label htmlFor="upload-logo-button" style={{ cursor: "pointer" }}>
              <Tooltip title="Enviar logo da startup">
                <div>
                  <div style={{textAlign: "center"}}>
                    <AddPhotoAlternateOutlinedIcon style={{ color: "black", fontSize: "3rem" }} />
                  </div>
                </div>
              </Tooltip>
            </label>
          </div>
          <div style={{textAlign: "center", fontSize: "0.8rem", position: "relative", marginBottom: "-0.5rem"}}>
            Logo da Startup<br />
            (máx. 500Kb)
          </div>
        </div>
        </>
      }
      </>
    );
  };

  return (
    <>
      <Header history={props.history} title="Minha Startup" />
      <Menu history={props.history} selected="login" />
      {isLoading ? (
        <div style={{textAlign: "center", paddingTop: "3rem"}}>
          <CircularProgress />
        </div>
      ) : (
        <>
        <div style={{maxWidth: "calc(100% - matches && 64px)", marginLeft: matches && "64px", padding: 0, marginRight: 0}}>
          <div className={`${classes.root} row`}>
            <div className="col s12 l2" style={{padding: 0, display: "flex", justifyContent: matches ? "left" : "center"}}>
              <Paper style={{ width: '128px', height: '128px' }}>
                <div style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}>
                  { submitting ? <CircularProgress /> : renderLogo() }
                  <input type="file" id="upload-logo-button" style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleLogoImageChange}
                  />
                </div>
              </Paper>
            </div>
            <div className="col s12 l10" style={{display: "flex", height: "128px", padding: 0, alignItems: "center"}}>
              <div>
                <span className={classes.title}>{currentCompany ? currentCompany.companyname : <>NOME DA STARTUP</>}</span><br />
                {currentCompany ? currentCompany.companydescription : <>Breve descrição da Startup (até 250 caracteres)</>}
              </div>
            </div>
          </div>
        </div>
        <div className={`gradient-45deg-orange-acsp`} style={{padding: 0, margin: "1rem 0 0 0", height: "8px", width: "100%"}}>
        </div>
        <div className="row" style={{maxWidth: "calc(100% - matches && 64px)", marginLeft: matches && "64px", padding: 0, marginRight: 0}}>
          <div className={`${classes.title} col s12 l6`}>
            <div className={classes.title}>
              <div style={{display: "flex", alignItems: "center", marginTop: "2rem"}}>
                <i className="material-icons orange-acsp-light">people</i>&nbsp;SÓCIOS
              </div>
              <div className={classes.partners} maxWidth="xs" style={{margin: "0.8rem 0 1rem 0", padding: 0}}>
                {['firstname','firstname_socio2','firstname_socio3','firstname_socio4','firstname_socio5','firstname_socio6'].map((partner, index) => {
                  return (
                    currentCompany && currentCompany[partner] &&
                    <Box key={index} className={classes.partner} borderBottom={2} style={{borderColor: "#666"}}>
                      {index + 1}) {currentCompany && currentCompany[partner]}
                    </Box>
                  );
                })}
              </div>
            </div>
            <div className={`${classes.title} col s12 l6`} style={{marginTop: "2rem", marginBottom: "1rem", width: "100%", padding: 0}}>
              <div style={{display: "flex", alignItems: "center"}}>
                <i className="material-icons orange-acsp-light">vpn_key</i>&nbsp;ALTERAR SENHA
              </div>
              <div style={{margin: 0, padding: 0}}>
                <Card className={classes.root} style={{margin: "0.5rem 0 0 0", padding: 0}}>
                  <CardContent style={{margin: 0, padding: "1rem"}}>
                    <div className="row margin">
                      <div className="input-field">
                        <input id="password" type="password" style={{fontSize: "1.2rem"}} 
                          value={oldPwd} onChange={e => setOldPwd(e.target.value)}
                        />
                        <label htmlFor="password" className="center-align">Senha atual</label>
                      </div>
                      <div className="input-field">
                        <input id="password" type="password" style={{fontSize: "1.2rem"}}
                          value={newPwd} onChange={e => setNewPwd(e.target.value)}
                        />
                        <label htmlFor="password" className="center-align">Nova senha</label>
                      </div>
                      <div className="input-field">
                        <input id="password" type="password" style={{fontSize: "1.2rem"}}
                          value={newPwdCopy} onChange={e => setNewPwdCopy(e.target.value)}
                        />
                        <label htmlFor="password" className="center-align">Confirme a nova senha</label>
                      </div>
                    </div>
                    <div style={{marginLeft: "0.8rem", marginRight: "0.8rem"}}>
                      {isPwdChanging ? (
                        <div className="progress">
                          <div className="indeterminate"/>
                        </div>
                      ) : (
                        <Button
                          className="gradient-45deg-orange-acsp"
                          variant="contained"
                          fullWidth 
                          style={{color: "#fff", fontSize: "1.2rem", padding: "0.1rem", borderRadius: "8px"}}
                          onClick={() => changePassword()}
                          >
                          Confirmar
                        </Button>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>

          <div className={`${classes.title} col s12 l6`} style={{paddingBottom: "2rem"}}>
            <div style={{display: "flex", alignItems: "center", marginTop: "2rem"}}>
              <i className="material-icons orange-acsp-light">album</i>&nbsp;AVANÇO AC BOOST
            </div>
            {
              [
                'Dados do Empreendedor Chave e Iniciais da Empresa.',
                'Dados do Empreendedor e Equipe.',
                'Produto / Serviço.',
                'Checkup de Maturidade.',
                'Check-up Investimentos.',
                'Checkup Jurídico e Governança.',
                'Envio do Vídeo Pitch.',
                'Agendamento da Entrevista.',
              ].map((stepText, index) => {
                return (
                  <Box key={index} className={classes.steps} >
                    <div style={{display: "flex", alignItems: "center", textAlign: "left"}}>
                      {currentCompany && (index + 1) <= currentCompany.etapa_atual ?
                        <><i className="material-icons" style={{color: "green"}}>done</i>&nbsp;Etapa {index + 1} - {stepText}</>
                        :
                        <><i className="material-icons" style={{color: "#999"}}>album</i>&nbsp;Etapa {index + 1} - {stepText}</>
                      }
                    </div>
                  </Box>
                );
              })
            }
          </div>
        </div>
        </>
      )}
    </>
  );
}

export default UserEdit;
