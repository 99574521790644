import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Stepper, Step, StepButton} from '@material-ui/core';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {toast} from 'react-toastify';
import Menu from '../../Components/Menu';
import Header from '../../Components/Header';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import Registration from './Registration';
import Entrepreneur from './Entrepreneur';
import ProductAndService from './ProductAndService';
import CheckupDevelopment from './CheckupMaturity';
import CheckupInvestments from './CheckupInvestments';
import CheckupLegalGovernance from './CheckupLegalGovernance';
import VideoPitch from './VideoPitch';
import SchedulingInterview from './SchedulingInterview';
import {restoreUserData} from '../../Core/localStorage';
import {actionPitchDetails} from './pitch.providers';
import {Steps} from '../../Common/Enums';

Pitch.propTypes = {
  successCallback: PropTypes.any,
  history: PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },

  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const muiTheme = createMuiTheme({
  overrides: {
      MuiStepIcon: {
          root: {
              color: "#e8580c",
              '&$active': {
                  color: "#e30f10",
              },
              '&$completed': {
                  color: "#e8580c",
              },
          },
      },
  }
});

const steps = [
  'Etapa 1',
  'Etapa 2',
  'Etapa 3',
  'Etapa 4',
  'Etapa 5',
  'Etapa 6',
  'Etapa 7',
  'Etapa 8'
];

function Pitch(props) {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:993px)');
  
  const dispatch = useDispatch();
  const {currentCompany} = useSelector(state => state.pitch);

  const userId = restoreUserData()._id;

  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getPitchDetails = () => {
    setIsLoading(true);
    dispatch(actionPitchDetails((success, rows, message) => {
      if (!success) {
        toast.info(`Erro: ${message}`);
      }
      setIsLoading(false);
    }));
  };

  useEffect(() => {
    getPitchDetails();
  }, [dispatch]);

  useEffect(()=> {
    if (currentCompany) {
      setActiveStep(currentCompany.etapa_atual - 1);
    }
  }, [currentCompany]);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const isStepClosed = (step) => {
    if (!process.env.REACT_APP_PROGRAM_ACBOOST_OPEN_STEPS.split(',').includes(step)) {
      return true;
    }
    return false;
  };

  const programClosedText = () => {
    return (
      <div style={{marginLeft: matches ? "6rem" : "2rem"}}>
        <h6>As inscrições da 3ª edição do Ac Boost estão encerradas.<br />
            <br />
            Nos vemos numa próxima edição!
        </h6>
      </div>
    );
  };

  function getStepContent(step) {
    if (isLoading) {
      return (
        <div style={{textAlign: "center", paddingTop: "3rem"}}>
          <CircularProgress />
        </div>
      );
    }
    switch (step) {
      case Steps.etapa1.index:
        if (isStepClosed(Steps.etapa1.value)) {
          return programClosedText();
        }
        return (currentCompany && currentCompany.etapa_atual >= 1
          ? <Registration user_uuid={userId} getPitchDetails={getPitchDetails}/>
          :
          <div style={{marginLeft: matches ? "6rem" : "2rem"}}>
            <h5>Etapa 1 - Dados do Empreendedor Chave e Iniciais da Empresa.</h5>
            <h6 className="orange-acsp-dark">Esta etapa ainda não está liberada.</h6>
          </div>
        );
      case Steps.etapa2.index:
        if (isStepClosed(Steps.etapa2.value)) {
          return programClosedText();
        }
        return (currentCompany && currentCompany.etapa_atual >= 2
          ? <Entrepreneur user_uuid={userId} getPitchDetails={getPitchDetails}/>
          :
          <div style={{marginLeft: matches ? "6rem" : "2rem"}}>
            <h5>Etapa 2 - Dados do Empreendedor e Equipe.</h5>
            <h6 className="orange-acsp-dark">Esta etapa ainda não está liberada.</h6>
            Complete as etapas anteriores para avançar no programa.
          </div>
        );
      case Steps.etapa3.index:
        if (isStepClosed(Steps.etapa3.value)) {
          return programClosedText();
        }
        return (currentCompany && currentCompany.etapa_atual >= 3
          ? <ProductAndService user_uuid={userId} getPitchDetails={getPitchDetails}/>
          :
          <div style={{marginLeft: matches ? "6rem" : "2rem"}}>
            <h5>Etapa 3 - Produto / Serviço.</h5>
            <h6 className="orange-acsp-dark">Esta etapa ainda não está liberada.</h6>
            Complete as etapas anteriores para avançar no programa.
          </div>
        );
      case Steps.etapa4.index:
        if (isStepClosed(Steps.etapa4.value)) {
          return programClosedText();
        }
        return (currentCompany && currentCompany.etapa_atual >= 4
          ? <CheckupDevelopment user_uuid={userId} getPitchDetails={getPitchDetails}/>
          :
          <div style={{marginLeft: matches ? "6rem" : "2rem"}}>
            <h5>Etapa 4 - Checkup de Maturidade.</h5>
            <h6 className="orange-acsp-dark">Esta etapa ainda não está liberada.</h6>
            Complete as etapas anteriores para avançar no programa.
          </div>
        );
      case Steps.etapa5.index:
        if (isStepClosed(Steps.etapa5.value)) {
          return programClosedText();
        }
        return (currentCompany && currentCompany.etapa_atual >= 5
          ? <CheckupInvestments user_uuid={userId} getPitchDetails={getPitchDetails}/>
          :
          <div style={{marginLeft: matches ? "6rem" : "2rem"}}>
            <h5>Etapa 5 - Check-up Investimentos.</h5>
            <h6 className="orange-acsp-dark">Esta etapa ainda não está liberada.</h6>
            Complete as etapas anteriores para avançar no programa.
          </div>
        );
      case Steps.etapa6.index:
        if (isStepClosed(Steps.etapa6.value)) {
          return programClosedText();
        }
        return (currentCompany && currentCompany.etapa_atual >= 6
          ? <CheckupLegalGovernance user_uuid={userId} getPitchDetails={getPitchDetails}/>
          :
          <div style={{marginLeft: matches ? "6rem" : "2rem"}}>
            <h5>Etapa 6 - Checkup Jurídico e Governança.</h5>
            <h6 className="orange-acsp-dark">Esta etapa ainda não está liberada.</h6>
            Complete as etapas anteriores para avançar no programa.
          </div>
        );
      case Steps.etapa7.index:
        if (isStepClosed(Steps.etapa7.value)) {
          return programClosedText();
        }
        return (currentCompany && currentCompany.etapa_atual >= 7
          ? <VideoPitch user_uuid={userId} getPitchDetails={getPitchDetails}/>
          :
          <div style={{marginLeft: matches ? "6rem" : "2rem"}}>
            <h5>Etapa 7 - Envio do Vídeo Pitch.</h5>
            <h6 className="orange-acsp-dark">Esta etapa ainda não está liberada.</h6>
            Complete as etapas anteriores para avançar no programa.
          </div>
        );
      case Steps.etapa8.index:
        if (isStepClosed(Steps.etapa8.value)) {
          return programClosedText();
        }
        return (currentCompany && currentCompany.etapa_atual >= 8
          ? <SchedulingInterview user_uuid={userId} getPitchDetails={getPitchDetails}/>
          :
          <div style={{marginLeft: matches ? "6rem" : "2rem"}}>
            <h5>Etapa 8 - Agendamento da Entrevista.</h5>
            <h6 className="orange-acsp-dark">Esta etapa ainda não está liberada.</h6>
            Complete as etapas anteriores para avançar no programa.
          </div>
        );
      default:
        return <div style={{marginLeft: matches ? "6rem" : "2rem"}}>
          <h6 className="orange-acsp-dark">Ops! Etapa desconhecida.</h6>
        </div>;
    }
  }

  return (
      <>
        <Header history={props.history} title="Cadastro Ac boost" userName={currentCompany && currentCompany.firstname}/>
        <Menu history={props.history} selected="pitch" />
        
        <div className={classes.root} style={{paddingLeft: matches ? "4rem" : 0}}>
          <div className="scrollContainer">
          <MuiThemeProvider theme={muiTheme}>
            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton style={{backgroundColor: "transparent"}} onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </MuiThemeProvider>
          </div>
        </div>

        {getStepContent(activeStep)}

      </>
  );
}

export default Pitch;
