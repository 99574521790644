import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import animationData from '../../statics/thank-you.json';
import Menu from '../../Components/Menu';
import Header from '../../Components/Header';

Thanks.propTypes = {
  history: PropTypes.object,
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};

function Thanks(props) {

  return (
      <>
        <Header history={props.history} title="Proposta" />
        <Menu history={props.history} selected="pitch" />
        <div className="container">
          <div id="main" className="main-full">
            <h3>Obrigado por preencher seus dados!</h3>
            <span>Analisaremos seu processo e entraremos em contato em breve.</span>

            <Lottie options={defaultOptions} height={400} width={400}/>
          </div>
        </div>
      </>
  );
}

export default Thanks;
