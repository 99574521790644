import axiosPure from 'axios';
import axios from '../../Core/axios-instance';
import { storeUserData, storeTokenData } from '../../Core/localStorage';
import { coreConstants } from '../../Core/core.constants';
import {handleResponseError} from '../../Common/Validations';

export function actionUserCreate(userInfo, callback) {
  return async () => {
    try {
      const response = await axiosPure.post(`${coreConstants.API}users`, userInfo );
      const data = response.data;
      if (data.token === undefined) {
        callback(false);
      } else {
        data.token = `bearer ${data.token}`;
        storeTokenData(data.token);
        storeUserData(data);
        callback(true);
      }
    } catch (e) {
      handleResponseError(e, callback);
    }
  };
}

export function actionLogin(email, password, callback) {
  return async () => {
    try {
      const response = await axiosPure.post(`${coreConstants.API}users/login`, { email, password });
      const data = response.data;
      if (data.token === undefined) {
        callback(false);
      } else {
        data.token = `bearer ${data.token}`;
        storeTokenData(data.token);
        storeUserData(data);
        callback(true);
      }
    } catch (e) {
      handleResponseError(e, callback);
    }
  };
}

export function actionForgotPassword(email, callback) {
  return async () => {
    try {
      await axiosPure.patch(`${coreConstants.API}users/forgot-password`, { email });
      callback && callback(true);
    } catch (e) {
      handleResponseError(e, callback);
    }
  };
}

export function actionResetPassword(token, password, callback) {
  return async () => {
    try {
      const response = await axiosPure.patch(`${coreConstants.API}users/reset-password`, { token, password });
      const data = response.data;
      if (data.token === undefined) {
        callback(false);
      } else {
        data.token = `Bearer ${data.token}`;
        storeTokenData(data.token);
        storeUserData(data);
        callback(true);
      }
    } catch (e) {
      handleResponseError(e, callback);
    }
  };
}

export function actionChangePassword(user_uuid, old_password, new_password, callback) {
  return async () => {
    try {
      const response = await axios.patch(`${coreConstants.API}users/change-password`, { user_uuid, old_password, new_password });
      const data = response.data;
      if (!data.success) {
        callback(false, data.message);
      } else {
        callback(true);
      }
    } catch (e) {
      handleResponseError(e, callback);
    }
  };
}

export function actionUploadLogo(file, callback) {
  return async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const uploadResult = await axios.post('/files/logo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const fileName = uploadResult.data.location;

      callback && callback(true, fileName);
    } catch (e) {
      handleResponseError(e, callback);
    }
  };
}

export const actionUpdateLogo = (userId, body, callback) => async () => {
  try {
    await axios.patch(`/pitch/${userId}/logo`, body);
    callback && callback(true);
  } catch (e) {
    handleResponseError(e, callback);
  }
};
