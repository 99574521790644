module.exports.is_cnpj = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g,'');

    if(!cnpj) return false;

    if (cnpj.length !== 14)
        return false;

    // Elimina CNPJs inválidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== digitos.charAt(1))
        return false;

    return true;
};

module.exports.is_cpf = (c) => {
    if((c = c.replace(/[^\d]/g,"")).length !== 11)
        return false;

    if (c === "00000000000")
        return false;

    let r;
    let s = 0;

    for (let i=1; i<=9; i++)
        s = s + parseInt(c[i-1]) * (11 - i);

    r = (s * 10) % 11;

    if ((r === 10) || (r === 11))
        r = 0;

    if (r !== parseInt(c[9]))
        return false;

    s = 0;

    for (let i = 1; i <= 10; i++)
        s = s + parseInt(c[i-1]) * (12 - i);

    r = (s * 10) % 11;

    if ((r === 10) || (r === 11))
        r = 0;

    return r === parseInt(c[10]);
};

module.exports.toStateName = (stateCode) => {
    switch(stateCode) {
        case 'AC': return 'Acre';
        case 'AL': return 'Alagoas';
        case 'AP': return 'Amapá';
        case 'AM': return 'Amazonas';
        case 'BA': return 'Bahia';
        case 'CE': return 'Ceará';
        case 'DF': return 'Distrito Federal';
        case 'ES': return 'Espírito Santo';
        case 'GO': return 'Goiás';
        case 'MA': return 'Maranhão';
        case 'MT': return 'Mato Grosso';
        case 'MS': return 'Mato Grosso do Sul';
        case 'MG': return 'Minas Gerais';
        case 'PA': return 'Pará';
        case 'PB': return 'Paraíba';
        case 'PR': return 'Paraná';
        case 'PE': return 'Pernambuco';
        case 'PI': return 'Piauí';
        case 'RJ': return 'Rio de Janeiro';
        case 'RN': return 'Rio Grande do Norte';
        case 'RS': return 'Rio Grande do Sul';
        case 'RO': return 'Rondônia';
        case 'RR': return 'Roraima';
        case 'SC': return 'Santa Catarina';
        case 'SP': return 'São Paulo';
        case 'SE': return 'Sergipe';
        case 'TO': return 'Tocantins';
        default: return '';
    }
};