export const Permission = Object.freeze({ adm: 'adm', regular: 'regular' });

export const States = [
  "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG",
  "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"
];

export const SystemStatus = Object.freeze({
  novo: 'novo',
  rascunho: 'rascunho',
  finalizado: 'finalizado',
});

export const Steps = Object.freeze({
  etapa1: {index: 0, value: '1', description: 'Etapa 1'},
  etapa2: {index: 1, value: '2', description: 'Etapa 2'},
  etapa3: {index: 2, value: '3', description: 'Etapa 3'},
  etapa4: {index: 3, value: '4', description: 'Etapa 4'},
  etapa5: {index: 4, value: '5', description: 'Etapa 5'},
  etapa6: {index: 5, value: '6', description: 'Etapa 6'},
  etapa7: {index: 6, value: '7', description: 'Etapa 7'},
  etapa8: {index: 7, value: '8', description: 'Etapa 8'},
});