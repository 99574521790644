import React from 'react';
import PropTypes from 'prop-types';

Header.propTypes = {
  title: PropTypes.string.isRequired,
  userName: PropTypes.string,
};

function Header(props) {

  return (
    <header className="page-topbar" id="header">
      <div className="navbar navbar-fixed">
        <nav className="navbar-main nav-collapsible sideNav-lock nav-collapsed background-black">
          <div className="nav-wrapper">
            <div className="header-search-wrapper hide-on-med-and-down">
              <i className="material-icons">web</i>
              <input
                className="header-search-input z-depth-2" type="text" disabled value={props.title}
              />
            </div>
            <div style={{color: "#fff", float: "right", paddingRight: "2rem"}}>
              {props.userName && `Olá ${props.userName}`}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;