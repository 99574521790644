import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NumberFormat from 'react-number-format';
import { actionUserCreate } from './login.providers';

NewUser.propTypes = {
  history: PropTypes.object,
};

const useStyles = makeStyles(() => ({
  resize: {
    fontSize: "1.3rem !important"
  },
}));

function NewUser(props) {

  const matches = useMediaQuery('(min-width:993px)');

  const classes = useStyles();

  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [regulationAccepted, setRegulationAccepted] = useState(false);
  const [useTermsAndPrivacyAccepted, setUseTermsAndPrivacyAccepted] = useState(false);

  const handleUserInfo = (field, value) => {
    setUserInfo({...userInfo, [field]: value});
  };

  const userCreate = (e) => {
    e.preventDefault();
    if (!useTermsAndPrivacyAccepted) {
      toast.info("📝 Você precisa aceitar os Termos de Uso + Política de Privacidade.");
      return;
    }
    if (!regulationAccepted) {
      toast.info("📝 Você precisa aceitar o Regulamento do AC boost.");
      return;
    }
    setLoading(true);
    dispatch(
        actionUserCreate(userInfo, (success, message) => {
        setLoading(false);
        if (success) {
          props.history.push(`pitch`);
          toast.success('Olá, seja bem-vinda(o).');
        } else {
          toast.info(message);
        }
      })
    );
  };

  return process.env.REACT_APP_PROGRAM_ACBOOST_LOGIN_CLOSED === "true" ?
    (
      <div id="login-page" className="blank-page" style={{backgroundColor: "#000"}}>
        <div style={{color: "rgb(212, 212, 212)", padding: "1rem"}}>Cadastramento Fechado.</div>
      </div>  
    )  : (
    <div id="login-page" className="blank-page" style={{backgroundColor: "#d72425"}}>
      <div className="row" style={{margin: 0, padding: 0, height: "100vh", width: "100%", backgroundColor: "#000", borderColor: "transparent", borderRadius: "0px 240px 0px 0px"}}>
        <div className="col s12" style={{
          backgroundColor: "#000",
          backgroundImage: `url('/app-assets/images/gallery/cycle2-login-background.webp')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover" }}>
          <div className="col s12 m12 l6" style={{float: "none", margin: "0 auto"}}>
            <h5 className="login-font">Nova Startup</h5>
            <span style={{color: "rgb(212, 212, 212)"}}>Bem-vindo ao programa Ac boost.</span>
            <div style={{marginBottom: "1.5rem"}}></div>
            <div style={{paddingLeft: "0", textAlign: "center"}}>
              <img src="/app-assets/images/logo/logo_ac_boost_2022-03.webp" alt="" style={{width: "9rem"}} />
            </div>
            <div style={{marginBottom: "0.8rem"}}></div>
          </div>
          <div>
            <div style={{float: "left", position: "absolute", marginLeft: "19rem", marginTop: "-4rem", display: !matches && "none"}}>
              <img src="/app-assets/images/gallery/quadrados-login.png" alt="" />
            </div>
            <div className="row center-card" style={{padding: "0.5rem"}}>
              <div className="col s12 m12 l6 z-depth-4 card-panel border-radius-6 login-card" style={{zIndex: 2}}>
                <form className="login-form">
                  <div className="row">
                    <div className="input-field col s12">
                      <h5 className="ml-2 login-font-box">Crie um Login aqui</h5>
                      <span className="ml-2" style={{fontSize: "0.85rem"}}>
                        Crie seu login para acessar a plataforma Ac boost e inicie seu cadastro no processo de seleção.
                      </span>
                    </div>
                  </div>
                  <div className="row margin">
                    <div className="col s12 m12 l6">
                      <div style={{display: "flex"}}>
                        <div className="col login-icons">
                          <i style={{fontSize: "2rem"}} className="material-icons prefix pt-2 orange-acsp-light">person</i>
                        </div>
                        <div className="col login-fields">
                          <TextField className="login-font-size"
                            InputProps={{disableUnderline: true, classes: {input: classes.resize}}}
                            fullWidth
                            label={'Nome do Sócio Responsável'}
                            value={userInfo.firstname}
                            onChange={e => handleUserInfo('firstname', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col s12 m12 l6">
                      <div style={{display: "flex"}}>
                        <div className="col login-icons">
                          <i style={{fontSize: "2rem"}} className="material-icons prefix pt-2 orange-acsp-light">email</i>
                        </div>
                        <div className="col login-fields">
                          <TextField
                            InputProps={{disableUnderline: true, classes: {input: classes.resize}}}
                            fullWidth
                            label={'E-mail do Sócio Responsável'}
                            value={userInfo.email}
                            onChange={e => handleUserInfo('email', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col s12 m12 l6">
                      <div style={{display: "flex"}}>
                        <div className="col login-icons">
                          <i style={{fontSize: "2rem"}} className="material-icons prefix pt-2 orange-acsp-light">contact_mail</i>
                        </div>
                        <div className="col login-fields">
                          <NumberFormat
                            InputProps={{disableUnderline: true, classes: {input: classes.resize}}}
                            fullWidth
                            customInput={TextField}
                            label={'CPF do Sócio Responsável'}
                            value={userInfo.cpf}
                            onValueChange={e => handleUserInfo('cpf', e.value)}
                            format={"###.###.###-##"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col s12 m12 l6">
                      <div style={{display: "flex"}}>
                        <div className="col login-icons">
                          <i style={{fontSize: "2rem"}} className="material-icons prefix pt-2 orange-acsp-light">business</i>
                        </div>
                        <div className="col login-fields">
                          <TextField
                            InputProps={{disableUnderline: true, classes: {input: classes.resize}}}
                            fullWidth
                            label={'Nome da Empresa'}
                            value={userInfo.companyname}
                            onChange={e => handleUserInfo('companyname', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col s12 m12 l6">
                      <div style={{display: "flex"}}>
                        <div className="col login-icons">
                          <i style={{fontSize: "2rem"}} className="material-icons prefix pt-2 orange-acsp-light">chrome_reader_mode</i>
                        </div>
                        <div className="col login-fields">
                          <NumberFormat
                            InputProps={{disableUnderline: true, classes: {input: classes.resize}}}
                            fullWidth
                            customInput={TextField}
                            label={'CNPJ'}
                            value={userInfo.cnpj}
                            onValueChange={e => handleUserInfo('cnpj', e.value)}
                            format={"##.###.###/####-##"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col s12 m12 l6">
                      <div style={{display: "flex"}}>
                        <div className="col login-icons">
                          <i style={{fontSize: "2rem"}} className="material-icons prefix pt-2 orange-acsp-light">vpn_key</i>
                        </div>
                        <div className="col login-fields">
                          <TextField
                            InputProps={{disableUnderline: true, classes: {input: classes.resize}}}
                            fullWidth
                            label={'Senha de Acesso'}
                            value={userInfo.password}
                            onChange={e => handleUserInfo('password', e.target.value)}
                            type={'password'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{padding: "2rem 1.2rem 0 1.2rem"}}>
                      <Checkbox
                        style={{paddingLeft: 0}}
                        onClick={() => setUseTermsAndPrivacyAccepted(!useTermsAndPrivacyAccepted)}
                        checked={useTermsAndPrivacyAccepted}
                        className="orange-acsp-light"
                      />
                      Li e concordo com os&nbsp;
                      <b style={{textDecoration: "underline", cursor: "pointer"}}>
                        <a href="documents/Política de Privacidade_BASE 07.07.2021.pdf" target="blank" style={{color: "#666"}}>
                          Termos de Uso + Política de Privacidade
                        </a>
                      </b>
                    </div>
                    <div style={{padding: "0.5rem 1.2rem 0 1.2rem"}}>
                      <Checkbox
                        style={{paddingLeft: 0}}
                        onClick={() => setRegulationAccepted(!regulationAccepted)}
                        checked={regulationAccepted}
                        className="orange-acsp-light"
                      />
                      Li e concordo com o&nbsp;
                      <b style={{textDecoration: "underline", cursor: "pointer"}}>
                        <a href="documents/Regulamento_Ac_boost_2022.pdf" target="blank" style={{color: "#666"}}>
                          Regulamento do Ac boost
                        </a>
                      </b>
                    </div>
                  </div>
                  <div className="col s12" style={{marginTop: "2rem"}}>
                    {loading ? (
                      <div className="progress">
                        <div className="indeterminate"></div>
                      </div>
                    ) : (
                      <button
                        onClick={userCreate}
                        className="btn waves-effect waves-light border-radius-8 gradient-45deg-orange-acsp normal-btn col s12">
                        Iniciar Cadastro
                      </button>
                    )}
                  </div>
                  <div className="row">
                    <div className="input-field col s6 m6 l6">
                      <p className="margin medium-small"></p>
                    </div>
                    <div className="input-field col s6 m6 l6">
                      <p className="margin right-align medium-small">{process.env.REACT_APP_VERSION}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewUser;