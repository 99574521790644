import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {Button} from '@material-ui/core';
import GetApp from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import {actionPitchUpdate} from './pitch.providers';
import {SystemStatus, Steps} from '../../Common/Enums';
import FormInstructions from './FormInstructions';

VideoPitch.propTypes = {
  successCallback: PropTypes.any,
  history: PropTypes.object,
  getPitchDetails: PropTypes.func.isRequired,
};

function VideoPitch(props) {

  const dispatch = useDispatch();
  const {currentCompany} = useSelector(state => state.pitch);
  const [company, setCompany] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setCompany({...currentCompany});
  }, [currentCompany]);

  const save = saveMode => {
    setIsSaving(true);
    company.system_status = saveMode;

    dispatch(actionPitchUpdate(company, (success, message, updateResult) => {
      if (!success) {
        toast.info(message);
      } else {
        if (updateResult.changedRows === 0) {
          toast.info('Seus dados já estão salvos. Nenhuma informação foi alterada.');
        } else {
          toast.success('💾 Informações atualizadas com sucesso!');
          props.getPitchDetails();
        }
      }
      setIsSaving(false);
    }));
  };

  const handleCompanyUpdate = (field, value) => {
    setCompany({...company, [field]: value});
  };
  const isDisabled = () => {
    return company && (currentCompany.system_status === SystemStatus.finalizado || currentCompany.etapa_atual !== Steps.etapa7.value);
  };

  return company && (
    <div className="mauticform_wrapper" style={{padding: "0.8rem"}}>

      <FormInstructions materialIconName="videocam">
        Chegou a hora de ver se a química rola de verdade e se vamos dar Match com sua startup!<br />
        Use o quadro abaixo para compartilhar o link do pitch de sua startup.<br />
        Lembre-se! O vídeo deve ter no máximo 3 minutos de duração. Vídeos mais longos serão assistidos apenas até o 3 minuto, não sendo considerado para análise o restante do tempo.<br />
        Deixamos abaixo nossa sugestão de roteiro de pitch livre para download com pontos que julgamos mais importantes para nossa avaliação.<br />
        Aceitaremos urls de YouTube, Vimeo ou outra plataforma de reprodução.<br />
      </FormInstructions>

      <form autoComplete="false" method="post" encType="multipart/form-data">
        
        <div className="mauticform-innerform">

          <div className="mauticform-page-wrapper mauticform-page-1">

            <div className="mauticform-row mauticform-freetext mauticform-field-1">
              <h3 className="mauticform-label">
                Envio do Vídeo Pitch
              </h3>
              <div className="mauticform-freetext">
                Envie o link contendo o vídeo do pitch gravado por você.
              </div>
            </div>

            <div>
              <Button
                className="btn waves-effect waves-light border-radius-8 gradient-45deg-orange-acsp normal-btn col s12"
                style={{ fontSize: "0.9rem", color: "#fff", fontWeight: "bold", textTransform: "none" }}
                href="documents/roteiro_pitch_ac_boost_2022.pdf"
                target="_blank"
                startIcon={<GetApp />}
              >
                Download do Roteiro de Pitch
              </Button>
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-2 mauticform-required" style={{marginTop: "1.5rem"}}>
              <label className="mauticform-label">Vídeo Pitch</label>
              <input
                className="mauticform-input"
                type="text" 
                value={company.video_pitch}
                placeholder="URL do Youtube contendo o vídeo do pitch"
                onChange={e => handleCompanyUpdate('video_pitch', e.target.value)}
                disabled={isDisabled()}
              />
            </div>

            <div className="mauticform-row mauticform-button-wrapper mauticform-field-37">
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0 0 1.5rem"}}>

                <>
                {!isDisabled() &&
                <button 
                  type="button" 
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.rascunho)}
                  disabled={isDisabled()}
                >
                  Salvar Rascunho
                </button>
                }

                <div style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                  {isSaving && <CircularProgress />}
                  {(isDisabled() && !isSaving) && <>Etapa Finalizada 🎉</>}
                </div>

                {!isDisabled() &&
                <button
                  type="button"
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.finalizado)}
                  disabled={isDisabled()}
                >
                  Finalizar Etapa
                </button>
                }
                </>

              </div>
            </div>

          </div>
        </div>

      </form>
    </div>
  );
}

export default VideoPitch;
