import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import {actionPitchUpdate} from './pitch.providers';
import {SystemStatus, Steps} from '../../Common/Enums';
import FormInstructions from './FormInstructions';

Entrepreneur.propTypes = {
  successCallback: PropTypes.any,
  history: PropTypes.object,
  getPitchDetails: PropTypes.func.isRequired,
};

function Entrepreneur(props) {

  const dispatch = useDispatch();
  const {currentCompany} = useSelector(state => state.pitch);
  const [company, setCompany] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [technology, setTechnology] = useState([]);

  useEffect(() => {
    setCompany({...currentCompany});
    setTechnology(currentCompany.principaistecnologiasstar ? currentCompany.principaistecnologiasstar.split(',') : []);
  }, [currentCompany]);

  const save = saveMode => {
    setIsSaving(true);
    company.system_status = saveMode;

    dispatch(actionPitchUpdate(company, (success, message, updateResult) => {
      if (!success) {
        toast.info(message);
      } else {
        if (updateResult.changedRows === 0) {
          toast.info('Seus dados já estão salvos. Nenhuma informação foi alterada.');
        } else {
          toast.success('💾 Informações atualizadas com sucesso!');
          props.getPitchDetails();
        }
      }
      setIsSaving(false);
    }));
  };

  const handleCompanyUpdate = (field, value) => {
    setCompany({...company, [field]: value});
  };

  const isDisabled = () => {
    return company && (currentCompany.system_status === SystemStatus.finalizado || currentCompany.etapa_atual !== Steps.etapa3.value);
  };

  const handleTechnology = (event) => {
    setTechnology(event.target.value);
    handleCompanyUpdate('principaistecnologiasstar', event.target.value);
  };

  const technologies = [
    '5G',
    'Inteligência Artificial',
    'Realidade Aumentada/Realidade Virtual',
    'Big Data & Analytics Autenticação Biométrica Blockchain',
    'Clean tech',
    'Cloud Computing',
    'Computação cognitiva',
    'Cybersegurança',
    'Deep Learning',
    'Meios de pagamentos',
    'Drones',
    'Gamification',
    'Home Health Monitoring',
    'Internet Of Things',
    'Machine Learning',
    'Machine2machine',
    'Mobile Payments Nanotecnologia NFC',
    'Robótica',
    'Economia Criativa, Compartilhada e Colaborativa',
    'Speech Analytics',
    'Rastreabilidade',
    'Assistentes Virtuais',
    'Outros',
  ];

  return company && (
    <div className="mauticform_wrapper" style={{padding: "0.8rem"}}>

      <FormInstructions materialIconName="shopping_basket">
        É aqui que a coisa fica mais interessante! Vamos entender seu produto/serviço, modelo de negócio, escalabilidade e todos esses aspectos tão importantes que tornam sua startup única.
      </FormInstructions>

      <form autoComplete="false" method="post" encType="multipart/form-data">
        
        <div className="mauticform-innerform">

          <div className="mauticform-page-wrapper mauticform-page-1">

            <div className="mauticform-row mauticform-freetext mauticform-field-1">
              <h3 className="mauticform-label">
                Produto / Serviço
              </h3>
              <div className="mauticform-freetext">
                Preencha com atenção todos os campos.
              </div>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Sua startup é digital? Digitais são aquelas que utilizam tecnologia em programação para transformar seus negócios em repetível e escalável globalmente.</label>
              <Select
                fullWidth
                value={company.suastartupedigital}
                onChange={e => handleCompanyUpdate('suastartupedigital', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Sim">Sim</MenuItem>
                <MenuItem value="Não">Não</MenuItem>
                <MenuItem value="Não Sei">Não Sei</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Seu produto / serviço é repetível e escalável?</label>
              <Select
                fullWidth
                value={company.seuprodutoservicoerepetiv}
                onChange={e => handleCompanyUpdate('seuprodutoservicoerepetiv', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Sim">Sim</MenuItem>
                <MenuItem value="Não">Não</MenuItem>
                <MenuItem value="Não Sei">Não Sei</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Qual o modelo de receita?</label>
              <Select
                fullWidth
                value={company.qualmodelodereceita}
                onChange={e => handleCompanyUpdate('qualmodelodereceita', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Assinatura">Assinatura</MenuItem>
                <MenuItem value="E-commerce">E-commerce</MenuItem>
                <MenuItem value="Venda de serviços on line">Venda de serviços on line</MenuItem>
                <MenuItem value="Venda de produtos">Venda de produtos</MenuItem>
                <MenuItem value="Marketplace">Marketplace</MenuItem>
                <MenuItem value="Consultoria">Consultoria</MenuItem>
                <MenuItem value="Intermediação">Intermediação</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Em qual estágio de maturidade sua startup se encontra?</label>
              <Select
                fullWidth
                value={company.estagiodematuridadedastar}
                onChange={e => handleCompanyUpdate('estagiodematuridadedastar', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Descoberta (teve um insight de solução, porém não iniciou a estruturação da ideia, nem as validações do negócio)">Descoberta (teve um insight de solução, porém não iniciou a estruturação da ideia, nem as validações do negócio)</MenuItem>
                <MenuItem value="Validação do Problema (iniciou o processo de interação com os clientes para validar as hipóteses do problema do cliente e de mercado)">Validação do Problema (iniciou o processo de interação com os clientes para validar as hipóteses do problema do cliente e de mercado)</MenuItem>
                <MenuItem value="Validação do MVP (Iniciou o processo de interação com os clientes para validar as hipóteses sobre a solução e a interação do MVP)">Validação do MVP (Iniciou o processo de interação com os clientes para validar as hipóteses sobre a solução e a interação do MVP)</MenuItem>
                <MenuItem value="Validação do Modelo de Negócio (Iniciou o processo de interação com os clientes para validar as hipóteses do Modelo de Negócios)">Validação do Modelo de Negócio (Iniciou o processo de interação com os clientes para validar as hipóteses do Modelo de Negócios)</MenuItem>
                <MenuItem value="Crescimento (já ativo e possui seus primeiros clientes pagantes)">Crescimento (já ativo e possui seus primeiros clientes pagantes)</MenuItem>
                <MenuItem value="Tração (vendas crescentes e seu faturamento é maior que seu custo)">Tração (vendas crescentes e seu faturamento é maior que seu custo)</MenuItem>
                <MenuItem value="Scale-up (sustenta um crescimento rápido, repetitivo e escalável durante um longo prazo)">Scale-up (sustenta um crescimento rápido, repetitivo e escalável durante um longo prazo)</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Modelo de negócio principal da Startup é:</label>
              <Select
                fullWidth
                value={company.modelonegocioprincipal}
                onChange={e => handleCompanyUpdate('modelonegocioprincipal', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="B2C (Negócios para clientes)">B2C (Negócios para clientes)</MenuItem>
                <MenuItem value="B2B (Business to Business)">B2B (Business to Business)</MenuItem>
                <MenuItem value="Ambos B2C e B2B">Ambos B2C e B2B</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Em qual área sua startup prioritariamente atua?</label>
              <Select
                fullWidth
                value={company.areastartupatuaprioritari}
                onChange={e => handleCompanyUpdate('areastartupatuaprioritari', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Varejotech">Varejotech</MenuItem>
                <MenuItem value="Fintech">Fintech</MenuItem>
                <MenuItem value="Edutech">Edutech</MenuItem>
                <MenuItem value="SAS (Software as A Service)">SAS (Software as A Service)</MenuItem>
                <MenuItem value="Automação/ Terceirização de Serviços (MKTG, Contábil, RH...)">Automação/ Terceirização de Serviços (MKTG, Contábil, RH...)</MenuItem>
                <MenuItem value="Plataformas de Comunicação ou Eventos">Plataformas de Comunicação ou Eventos</MenuItem>
                <MenuItem value="Tecnologias para Mídias Sociais">Tecnologias para Mídias Sociais</MenuItem>
                <MenuItem value="Outro">Outro</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Selecione quais principais tecnologias/inovação sua startup considera para a solução proposta:</label>
              <Select
                multiple
                fullWidth
                value={technology}
                onChange={!isDisabled() && handleTechnology}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                >
                {technologies.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox disabled={isDisabled()} checked={technology.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="mauticform-row mauticform-button-wrapper mauticform-field-37">
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0 0 1.5rem"}}>

                <>
                {!isDisabled() &&
                <button 
                  type="button" 
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.rascunho)}
                  disabled={isDisabled()}
                >
                  Salvar Rascunho
                </button>
                }

                <div style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                  {isSaving && <CircularProgress />}
                  {(isDisabled() && !isSaving) && <>Etapa Finalizada 🎉</>}
                </div>

                {!isDisabled() &&
                <button
                  type="button"
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.finalizado)}
                  disabled={isDisabled()}
                >
                  Finalizar Etapa
                </button>
                }
                </>

              </div>
            </div>

          </div>
        </div>

      </form>
    </div>
  );
}

export default Entrepreneur;
