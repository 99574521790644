import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import {DateTime} from 'luxon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import {actionPitchUpdate, actionGetInfoCEP} from './pitch.providers';
import {SystemStatus, Steps} from '../../Common/Enums';
import FormInstructions from './FormInstructions';
import {toStateName} from '../../utils/helper';

Registration.propTypes = {
  successCallback: PropTypes.any,
  history: PropTypes.object,
  getPitchDetails: PropTypes.func.isRequired,
};

function Registration(props) {

  const dispatch = useDispatch();
  const {currentCompany} = useSelector(state => state.pitch);
  const [company, setCompany] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [cepData, setCepData] = useState(['', {}]);
  const [cepPartnerStatus, setCepPartnerStatus] = useState({});
  const [cepCompanyStatus, setCepCompanyStatus] = useState({});

  useEffect(() => {
    currentCompany && setCompany({...currentCompany});
  }, [currentCompany]);

  const save = saveMode => {
    setIsSaving(true);
    company.system_status = saveMode;

    dispatch(actionPitchUpdate(company, (success, message, updateResult) => {
      if (!success) {
        toast.info(message);
      } else {
        if (updateResult.changedRows === 0) {
          toast.info('Seus dados já estão salvos. Nenhuma informação foi alterada.');
        } else {
          toast.success('💾 Informações atualizadas com sucesso!');
          props.getPitchDetails();
        }
      }
      setIsSaving(false);
    }));
  };

  const getInfoCEP = (cep, field) => {
    if (field === 'zipcode') {
      setCepPartnerStatus({message: 'Aguarde! Consultando CEP...', color: "darkgrey"});
    }
    if (field === 'companyzipcode') {
      setCepCompanyStatus({message: 'Aguarde! Consultando CEP...', color: "darkgrey"});
    }
    dispatch(actionGetInfoCEP(cep, field, (success, data) => {
      if (success) {
        setCepData(data);
        setCepPartnerStatus({});
        setCepCompanyStatus({});
      } else {
        setCepData([field, {}]);
        if (field === 'zipcode') {
          setCepPartnerStatus({message: '(não encontrado)', color: "red"});
        }
        if (field === 'companyzipcode') {
          setCepCompanyStatus({message: '(não encontrado)', color: "red"});
        }
      }
    }));
  };

  const handleCompanyUpdate = (field, value) => {
    setCompany({...company, [field]: value});

    if ((field === 'zipcode' || field === 'companyzipcode') && value.length === 8) {
      if ((field === 'zipcode' && value !== company.zipcode) || (field === 'companyzipcode' && value !== company.companyzipcode)) {
        getInfoCEP(value, field);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(cepData[1]).length > 0) {
      if (cepData[0] === 'zipcode') {
        setCompany({
          ...company,
          'address1': `${cepData[1]['logradouro']}, (informar o número/complemento) - ${cepData[1]['bairro']}`,
          'estado': toStateName(cepData[1]['uf']),
          'city': cepData[1]['localidade'],
        });
        setCepPartnerStatus({});
      }
      if (cepData[0] === 'companyzipcode') {
        setCompany({
          ...company,
          'companyaddress1': `${cepData[1]['logradouro']}, (informar o número/complemento) - ${cepData[1]['bairro']}`,
          'companystate1': toStateName(cepData[1]['uf']),
          'companycity': cepData[1]['localidade'],
        });
        setCepCompanyStatus({});
      }
    }
  }, [cepData]);

  const isDisabled = () => {
    return company && (currentCompany.system_status === SystemStatus.finalizado || currentCompany.etapa_atual !== Steps.etapa1.value);
  };

  const showQuestionLabel = (question) => {
    switch (question) {
      case 'Indicação': return 'Informe o código de Indicação que você recebeu';
      case 'Mídias sociais': return 'Através de qual Rede Social você conheceu o programa?';
      case 'Outro': return 'Informe por onde ficou sabendo do Ac boost';
      default: return '';
    }
  };

  return company && (
    <div className="mauticform_wrapper" style={{padding: "0.8rem"}}>

      <FormInstructions materialIconName="contact_mail">
        Seja bem-vindo ao Ac boost!<br />
        Você está a poucas etapas de levar sua startup ao próximo nível.<br />
        Para esse match  rolar e darmos um boost no seu negócio, tire um minuto para ler as informações abaixo e garantir que o preenchimento de sua inscrição seja feito corretamente. Lembre-se: a química tem que ser espontânea, estamos aqui para ajudar seu negócio e então, quanto mais sincera suas respostas forem, mais poderemos ajudá-lo.<br /><br />
        NO CASO DE DÚVIDAS<br />
        É comum que possam surgir dúvidas e, no caso de alguma, fique à vontade para enviá-las para nós através do botão 'Dúvidas ou problemas?' que você pode encontrar na aba 'Informações Ac boost' aqui dentro da plataforma de cadastro, na coluna de menu a esquerda em sua tela."<br /><br />
        ETAPAS DE INSCRIÇÃO<br />
        Nas etapas 1 e 2 queremos conhecer um pouco mais sobre a startup e o empreendedor-chave do negócio, que é o responsável pelo preenchimento das informações no questionário e será nosso principal ponto de contato na startup, inclusive recebendo os e-mails referentes às etapas do programa.<br />
        Nossa plataforma de inscrições nos fornece relatórios sobre sua evolução à medida que sua startup finaliza cada etapa. Assim, a partir da etapa 3, para que sua inscrição avance, será preciso aguardar nossa análise e isso pode levar um tempinho para validarmos que estará apto a seguir para próxima etapa.<br /><br />
        COMO SEI QUE PASSEI PARA PROXIMA ETAPA<br />
        Você sempre será notificado pelo e-mail do empreendedor-chave, com o informe de aprovação ou não para as próximas etapas. Algumas aprovações poderão levar mais tempo que outras e, outra forma de você verificar se sua nova etapa já está aberta é atualizando a página em seu navegador.<br /><br />
        FINALIZANDO CORRETAMENTE UMA ETAPA<br />
        Em todas as etapas existem duas opções ao fim das perguntas: “Salvar rascunho” ou “Finalizar etapa”. Nós só receberemos suas informações de uma etapa quando você clicar no botão “Finalizar etapa”. Assim, salvando seu rascunho, você pode sair da plataforma e no seu próximo login as informações já preenchidas estarão salvas.
      </FormInstructions>

      <form autoComplete="false" method="post" encType="multipart/form-data">

        <div className="mauticform-innerform">

          <div className="mauticform-page-wrapper mauticform-page-1">

            <div style={{padding: "2rem 0 0.5rem 0"}}>
              <div className="mauticform-row mauticform-select mauticform-field-33 mauticform-required">
                <label className="mauticform-label">Como soube do Ac boost?</label>
                <Select
                  fullWidth
                  value={company.como_soube_ac_boost || ''}
                  onChange={e => handleCompanyUpdate('como_soube_ac_boost', e.target.value)}
                  disabled={isDisabled()}
                >
                  <MenuItem value="Indicação">Indicação</MenuItem>
                  <MenuItem value="Mídias sociais">Mídias sociais</MenuItem>
                  <MenuItem value="Mail-marketing ACSP">Mail-marketing ACSP</MenuItem>
                  <MenuItem value="Mail-marketing AB Startup">Mail-marketing AB Startup</MenuItem>
                  <MenuItem value="Mail-marketing SEBRAE">Mail-marketing SEBRAE</MenuItem>
                  <MenuItem value="Mail-marketing ADESAMPA">Mail-marketing ADESAMPA</MenuItem>
                  <MenuItem value="Outro">Outro</MenuItem>
                </Select>
              </div>
              {company.como_soube_ac_boost && ['Indicação','Mídias sociais','Outro'].includes(company.como_soube_ac_boost) &&
                <div className="mauticform-row mauticform-text mauticform-field-2 mauticform-required">
                  <label className="mauticform-label">{showQuestionLabel(company.como_soube_ac_boost)}</label>
                  <input
                    className="mauticform-input"
                    type="text"
                    value={company.resposta_como_soube_ac_bo}
                    onChange={e => handleCompanyUpdate('resposta_como_soube_ac_bo', e.target.value)}
                    disabled={isDisabled()}
                    maxLength="100"
                  />
                </div>
              }
            </div>

            <div className="mauticform-row mauticform-freetext mauticform-field-1">
              <h3 className="mauticform-label">
                Dados do Empreendedor Chave e Iniciais da Empresa
              </h3>
              <div className="mauticform-freetext">
                Preencha com atenção todos os campos.
              </div>
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-2 mauticform-required">
              <label className="mauticform-label">Nome Completo</label>
              <input
                className="mauticform-input"
                type="text"
                value={company.firstname}
                placeholder="Obrigatório ser um sócio da empresa"
                onChange={e => handleCompanyUpdate('firstname', e.target.value)}
                maxLength="191"
                disabled={isDisabled()}
              />
            </div>

            <div className="mauticform-row mauticform-email mauticform-field-3 mauticform-required">
              <label className="mauticform-label">E-mail</label>
              <input value={company.email} className="mauticform-input" type="email" disabled={true} maxLength="191"/>
            </div>

            <div className="mauticform-row mauticform-number mauticform-field-4 mauticform-required">
              <label className="mauticform-label">CPF</label>
              <NumberFormat
                InputProps={{disableUnderline: true}}
                fullWidth
                customInput={TextField}
                value={company.cpf}
                onValueChange={e => handleCompanyUpdate('cpf', e.value)}
                format={company && "###.###.###-##"}
                disabled={isDisabled()}
              />
            </div>

            <div className="mauticform-row mauticform-date mauticform-field-5 mauticform-required">
              <label className="mauticform-label">Data de Nascimento</label>
              <input value={company && DateTime.fromISO(company.birthdaydate).toFormat('yyyy-MM-dd')} className="mauticform-input" type="date" onChange={e => handleCompanyUpdate('birthdaydate', DateTime.fromISO(e.target.value).toFormat('yyyy-MM-dd'))} disabled={isDisabled()}/>
            </div>

            <div className="mauticform-row mauticform-number mauticform-field-7 mauticform-required">
              <label className="mauticform-label">
                CEP&nbsp;
                <span style={{fontSize: "0.8rem", color: cepPartnerStatus.color}}>{cepPartnerStatus.message}</span>
              </label>
              <NumberFormat
                InputProps={{disableUnderline: true}}
                fullWidth
                customInput={TextField}
                value={company.zipcode}
                onValueChange={e => handleCompanyUpdate('zipcode', e.value)}
                format={"#####-###"}
                disabled={isDisabled()}
              />
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-6 mauticform-required">
              <label className="mauticform-label">Endereço</label>
              <input value={company.address1} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate('address1', e.target.value)} disabled={isDisabled()} maxLength="191"/>
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-9 mauticform-required">
              <label className="mauticform-label">Cidade</label>
              <input value={company.city} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate('city', e.target.value)} disabled={isDisabled()} maxLength="191"/>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-8 mauticform-required">
              <label className="mauticform-label">Estado</label>
              <Select
                fullWidth
                value={company.estado}
                onChange={e => handleCompanyUpdate('estado', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Acre">AC</MenuItem>
                <MenuItem value="Alagoas">AL</MenuItem>
                <MenuItem value="Amapá">AP</MenuItem>
                <MenuItem value="Amazonas">AM</MenuItem>
                <MenuItem value="Bahia">BA</MenuItem>
                <MenuItem value="Ceará">CE</MenuItem>
                <MenuItem value="Distrito Federal">DF</MenuItem>
                <MenuItem value="Espírito Santo">ES</MenuItem>
                <MenuItem value="Goiás">GO</MenuItem>
                <MenuItem value="Maranhão">MA</MenuItem>
                <MenuItem value="Mato Grosso">MT</MenuItem>
                <MenuItem value="Mato Grosso do Sul">MS</MenuItem>
                <MenuItem value="Minas Gerais">MG</MenuItem>
                <MenuItem value="Pará">PA</MenuItem>
                <MenuItem value="Paraíba">PB</MenuItem>
                <MenuItem value="Paraná">PR</MenuItem>
                <MenuItem value="Pernambuco">PE</MenuItem>
                <MenuItem value="Piauí">PI</MenuItem>
                <MenuItem value="Rio de Janeiro">RJ</MenuItem>
                <MenuItem value="Rio Grande do Norte">RN</MenuItem>
                <MenuItem value="Rio Grande do Sul">RS</MenuItem>
                <MenuItem value="Rondônia">RO</MenuItem>
                <MenuItem value="Roraima">RR</MenuItem>
                <MenuItem value="Santa Catarina">SC</MenuItem>
                <MenuItem value="São Paulo">SP</MenuItem>
                <MenuItem value="Sergipe">SE</MenuItem>
                <MenuItem value="Tocantins">TO</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-number mauticform-field-10 mauticform-required">
              <label className="mauticform-label">Celular com DDD</label>
              <NumberFormat
                InputProps={{disableUnderline: true}}
                fullWidth
                customInput={TextField}
                value={company.phone}
                onValueChange={e => handleCompanyUpdate('phone', e.value)}
                format={company && company.phone && company.phone.length < 11 ? "(##) ####-#####" : "(##) #####-####"}
                disabled={isDisabled()}
              />
            </div>

            <div className="mauticform-row mauticform-freetext mauticform-field-11">
              <h3 className="mauticform-label">
                Mídias Sociais:
              </h3>
              <div className="mauticform-freetext"></div>
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-12">
              <label className="mauticform-label">Instagram</label>
              <input value={company.instagram} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate('instagram', e.target.value)} disabled={isDisabled()} maxLength="120"/>
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-13">
              <label className="mauticform-label">LinkedIn</label>
              <input value={company.linkedin} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate('linkedin', e.target.value)} disabled={isDisabled()} maxLength="120"/>
            </div>

          </div>

          <div className="mauticform-page-wrapper mauticform-page-2">

            <div className="mauticform-row mauticform-freetext mauticform-field-16">
              <h3 className="mauticform-label">
                Dados Iniciais da Empresa
              </h3>
              <div className="mauticform-freetext">
                Preencha com atenção todos os campos.
              </div>
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-17 mauticform-required">
              <label className="mauticform-label">Qual o nome da Startup?</label>
              <input value={company.companyname} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate('companyname', e.target.value)} disabled={isDisabled()}/>
            </div>

            <div className="mauticform-row mauticform-number mauticform-field-18 mauticform-required">
              <label className="mauticform-label">CNPJ</label>
              <NumberFormat
                InputProps={{disableUnderline: true}}
                fullWidth
                customInput={TextField}
                value={company.cnpj}
                onValueChange={e => handleCompanyUpdate('cnpj', e.value)}
                format={company && "##.###.###/####-##"}
                disabled={isDisabled()}
              />
            </div>

            <div className="mauticform-row mauticform-number mauticform-field-20 mauticform-required">
              <label className="mauticform-label">
                CEP&nbsp;
                <span style={{fontSize: "0.8rem", color: cepCompanyStatus.color}}>{cepCompanyStatus.message}</span>
              </label>
              <NumberFormat
                InputProps={{disableUnderline: true}}
                fullWidth
                customInput={TextField}
                value={company.companyzipcode}
                onValueChange={e => handleCompanyUpdate('companyzipcode', e.value)}
                format={"#####-###"}
                disabled={isDisabled()}
              />
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-19 mauticform-required">
              <label className="mauticform-label">Endereço</label>
              <input value={company.companyaddress1} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate('companyaddress1', e.target.value)} disabled={isDisabled()} maxLength="191"/>
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-22 mauticform-required">
              <label className="mauticform-label">Cidade</label>
              <input value={company.companycity} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate('companycity', e.target.value)} disabled={isDisabled()} maxLength="191"/>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-8 mauticform-required">
              <label className="mauticform-label">Estado</label>
              <Select
                fullWidth
                value={company.companystate1}
                onChange={e => handleCompanyUpdate('companystate1', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Acre">AC</MenuItem>
                <MenuItem value="Alagoas">AL</MenuItem>
                <MenuItem value="Amapá">AP</MenuItem>
                <MenuItem value="Amazonas">AM</MenuItem>
                <MenuItem value="Bahia">BA</MenuItem>
                <MenuItem value="Ceará">CE</MenuItem>
                <MenuItem value="Distrito Federal">DF</MenuItem>
                <MenuItem value="Espírito Santo">ES</MenuItem>
                <MenuItem value="Goiás">GO</MenuItem>
                <MenuItem value="Maranhão">MA</MenuItem>
                <MenuItem value="Mato Grosso">MT</MenuItem>
                <MenuItem value="Mato Grosso do Sul">MS</MenuItem>
                <MenuItem value="Minas Gerais">MG</MenuItem>
                <MenuItem value="Pará">PA</MenuItem>
                <MenuItem value="Paraíba">PB</MenuItem>
                <MenuItem value="Paraná">PR</MenuItem>
                <MenuItem value="Pernambuco">PE</MenuItem>
                <MenuItem value="Piauí">PI</MenuItem>
                <MenuItem value="Rio de Janeiro">RJ</MenuItem>
                <MenuItem value="Rio Grande do Norte">RN</MenuItem>
                <MenuItem value="Rio Grande do Sul">RS</MenuItem>
                <MenuItem value="Rondônia">RO</MenuItem>
                <MenuItem value="Roraima">RR</MenuItem>
                <MenuItem value="Santa Catarina">SC</MenuItem>
                <MenuItem value="São Paulo">SP</MenuItem>
                <MenuItem value="Sergipe">SE</MenuItem>
                <MenuItem value="Tocantins">TO</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-tel mauticform-field-23 mauticform-required">
              <label className="mauticform-label">Telefone com DDD</label>
              <NumberFormat
                InputProps={{disableUnderline: true}}
                fullWidth
                customInput={TextField}
                value={company.companyphone}
                onValueChange={e => handleCompanyUpdate('companyphone', e.value)}
                format={company && company.companyphone && company.companyphone.length < 11 ? "(##) ####-#####" : "(##) #####-####"}
                disabled={isDisabled()}
              />
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-24">
              <label className="mauticform-label">Página da Web</label>
              <input value={company.companywebsite} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate('companywebsite', e.target.value)} disabled={isDisabled()} maxLength="255"/>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-25 mauticform-required">
              <label className="mauticform-label">Tipo de Empresa</label>
              <Select
                fullWidth
                value={company.companytype}
                onChange={e => handleCompanyUpdate('companytype', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Simples Nacional">Simples Nacional</MenuItem>
                <MenuItem value="Lucro Presumido">Lucro Presumido</MenuItem>
                <MenuItem value="Lucro Real">Lucro Real</MenuItem>
                <MenuItem value="EIRELI">EIRELI</MenuItem>
              </Select>

              <label className="mauticform-label" style={{marginTop: 20}}>Qual o objetivo da Startup?</label>
              <span className="mauticform-helpmessage">Nos conte resumidamente</span>
              <textarea className="mauticform-textarea" value={company.companydescription} onChange={e => handleCompanyUpdate('companydescription', e.target.value)} disabled={isDisabled()}></textarea>
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-27 mauticform-required">
              <label className="mauticform-label">Qual o segmento de atuação e qual a atividade econômica?</label>
              <input value={company.segmentoatuacao} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate('segmentoatuacao', e.target.value)} disabled={isDisabled()} maxLength="255"/>
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-28 mauticform-required">
              <label className="mauticform-label">Qual o produto ou serviço que a Startup entrega?</label>
              <input value={company.produtoouservico} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate('produtoouservico', e.target.value)} disabled={isDisabled()} maxLength="255"/>
            </div>

            <div className="mauticform-row mauticform-text mauticform-field-29 mauticform-required">
              <label className="mauticform-label">Qual o objetivo da sua Startup no programa Ac boost?</label>
              <input value={company.objetivonopateo76} className="mauticform-input" type="text" onChange={e => handleCompanyUpdate('objetivonopateo76', e.target.value)} disabled={isDisabled()} maxLength="255"/>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-30 mauticform-required">
              <label className="mauticform-label">O seu negócio é repetível e escalável?</label>
              <Select
                fullWidth
                value={company.o_seu_negocio_e_repetivel}
                onChange={e => handleCompanyUpdate('o_seu_negocio_e_repetivel', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Sim, com certeza">Sim, com certeza</MenuItem>
                <MenuItem value="Pode ser com alguns ajustes">Pode ser com alguns ajustes</MenuItem>
                <MenuItem value="Não">Não</MenuItem>
                <MenuItem value="Não sei">Não sei</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-31 mauticform-required">
              <label className="mauticform-label">Quantos sócios dedicam tempo às atividades da empresa / startup?</label>
              <span className="mauticform-helpmessage">Obs.: Exluem-se desta pergunta os sócios investidores, mentores, parceiros ou qualquer pessoa que não atua no dia a dia da startup.</span>
              <Select
                fullWidth
                value={company.quantos_socios_dedicam_te}
                onChange={e => handleCompanyUpdate('quantos_socios_dedicam_te', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4 ou mais">4 ou mais</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-32 mauticform-required">
              <label className="mauticform-label">Quantos sócios da Startup se dispõe a participar das nossas trilhas de treinamento?</label>
              <Select
                fullWidth
                value={company.quantos_socios_da_startup}
                onChange={e => handleCompanyUpdate('quantos_socios_da_startup', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Nenhum">Nenhum</MenuItem>
                <MenuItem value="Um sócio">Um sócio</MenuItem>
                <MenuItem value="Dois sócios">Dois sócios</MenuItem>
                <MenuItem value="Três ou mais sócios">Três ou mais sócios</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-33 mauticform-required">
              <label className="mauticform-label">Sua startup já participou de algum outro programa de desenvolvimento de novos negócios repetíveis e escaláveis?</label>
              <Select
                fullWidth
                value={company.sua_startup_ja_participou}
                onChange={e => handleCompanyUpdate('sua_startup_ja_participou', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Sim">Sim</MenuItem>
                <MenuItem value="Não">Não</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Há quanto tempo sua startup está em desenvolvimento, desde o surgimento da ideia, envolvimento dos principais sócios e primeiros passos?</label>
              <Select
                fullWidth
                value={company.ha_quanto_tempo_sua_start}
                onChange={e => handleCompanyUpdate('ha_quanto_tempo_sua_start', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Menos que 6 meses">Menos que 6 meses</MenuItem>
                <MenuItem value="Entre 6 e 12 meses">Entre 6 e 12 meses</MenuItem>
                <MenuItem value="Entre 12 e 24 meses">Entre 12 e 24 meses</MenuItem>
                <MenuItem value="Entre 24 e 36 meses">Entre 24 e 36 meses</MenuItem>
                <MenuItem value="Mais que 36 meses">Mais que 36 meses</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Quantas pessoas trabalham full time na Startup?</label>
              <Select
                fullWidth
                value={company.quantas_pessoas_trabalham}
                onChange={e => handleCompanyUpdate('quantas_pessoas_trabalham', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Nenhum membro atua full time">Nenhum membro atua full time</MenuItem>
                <MenuItem value="Até 3 pessoas">Até 3 pessoas</MenuItem>
                <MenuItem value="De 4 a 6 pessoas">De 4 a 6 pessoas</MenuItem>
                <MenuItem value="De 7 a 10 pessoas">De 7 a 10 pessoas</MenuItem>
                <MenuItem value="Mais de 10 pessoas">Mais de 10 pessoas</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-button-wrapper mauticform-field-37">
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0 0 1.5rem"}}>

                <>
                {!isDisabled() &&
                <button
                  type="button"
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.rascunho)}
                  disabled={isDisabled()}
                >
                  Salvar Rascunho
                </button>
                }

                <div style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                  {isSaving && <CircularProgress />}
                  {(isDisabled() && !isSaving) && <>Etapa Finalizada 🎉</>}
                </div>

                {!isDisabled() &&
                <button
                  type="button"
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.finalizado)}
                  disabled={isDisabled()}
                >
                  Finalizar Etapa
                </button>
                }
                </>

              </div>
            </div>

          </div>
        </div>

      </form>
    </div>
  );
}

export default Registration;
