import React from 'react';
import PropTypes from 'prop-types';

FormInstructions.propTypes = {
    children: PropTypes.any,
    materialIconName: PropTypes.string,
};

function FormInstructions(props) {

    return (
        <div className="form-step-instructions">
            <i className="material-icons orange-acsp-light" style={{paddingRight: 8, float: "left"}}>
                {props.materialIconName}
            </i>
            {props.children}
        </div>
    );

}

export default FormInstructions;