export const coreConstants = {
  // LOGIN
  API: process.env.REACT_APP_API,
  CLEAR: "CLEAR",
  LOGOUT: "LOGOUT",
  STORAGE_TOKEN: "STORAGE_TOKEN",
  STORAGE_USER: "STORAGE_USER",

  // PITCHES
  PITCH_DETAILS: 'PITCH_DETAILS',
};
