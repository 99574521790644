import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { actionForgotPassword } from "./login.providers";
import { toast } from 'react-toastify';

ForgotPassword.propTypes = {
  history: PropTypes.object,
};

function ForgotPassword(props) {

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const doForgotPassword = () => {
    setLoading(true);
    dispatch(
        actionForgotPassword(email, (success, message) => {
        setLoading(false);
        if (success) {
          props.history.push('/');
          toast.success('Verifique o seu e-mail para redefinir sua senha');
        } else {
          toast.info(message);
        }
      })
    );
  };

  return (
    <div id="login-page" className="login-bg blank-page">
      <h5 className="login-font-box" style={{margin: 0, padding: "1rem"}}>Ac boost</h5>
      <div style={{marginBottom: "1.5rem"}}></div>
      <div className="row" style={{display: "flex", height: "50vh", alignItems: "center"}}>
        <div className="col s12">
          <div className="container"><div className="row center-card">
            <div className="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
              <form className="login-form">
                <div className="row">
                  <div className="input-field col s12">
                    <h5 className="ml-4 login-font-box">Esqueceu a Senha</h5>
                  </div>
                </div>
                <div className="row margin">
                  <div className="input-field col s12">
                    <i className="material-icons prefix pt-2 orange-acsp-light">email</i>
                    <input className="login-font-size" id="username" type="text" onChange={handleEmailChange} value={email} />
                    <label htmlFor="username" className="center-align">E-mail</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12">
                    {loading ? (
                      <div className="progress">
                        <div className="indeterminate"></div>
                      </div>
                    ) : (
                        <button
                          type="submit"
                          onClick={doForgotPassword}
                          className="btn waves-effect waves-light border-radius-8 gradient-45deg-orange-acsp normal-btn col s12">
                          Enviar email
                        </button>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s6 m6 l6">
                    <p className="margin medium-small"></p>
                  </div>
                  <div className="input-field col s6 m6 l6">
                    <p className="margin right-align medium-small">{process.env.REACT_APP_VERSION}</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;