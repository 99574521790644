import React from 'react';
import PropTypes from 'prop-types';
import GetApp from '@material-ui/icons/GetApp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button } from '@material-ui/core';
import Menu from '../../Components/Menu';
import Header from '../../Components/Header';
import { WarningOutlined } from '@material-ui/icons';

Home.propTypes = {
  successCallback: PropTypes.any,
  history: PropTypes.object,
};

function Home(props) {


  const matches = useMediaQuery('(min-width:993px)');

  const smallDevices = useMediaQuery('(max-height:700px)');

  return (
    <div id="login-page" className="blank-page">
      <Header history={props.history} title="Informações Ac boost" />
      <Menu history={props.history} selected="home" />
      <div className="row" style={{
        height: "calc(100vh - 56px)",
        backgroundColor: "#000" }}>
        
        <img src="/app-assets/images/gallery/cycle2-home-background.webp" style={{
          width: "100%", height: matches && "100%", position: "absolute", bottom: "0", zIndex: "0"
          }}
        />

        <div className="col s12 m5 l5" style={{
          display: "flex",
          flexDirection: "column",
          marginTop: matches ? "50px" : "10px",
          marginLeft: matches ? "64px" : "0",
          fontFamily: "Alatsi",
          fontSize: "1.65rem",
          color: "#FFF" }}>
          <div style={{margin: "0 auto", zIndex: "1"}}>
            <div>
              BEM VINDO AO
            </div>
            <div style={{marginTop: "1rem", marginBottom: smallDevices ? "0" : "2rem"}}>
              <img src="/app-assets/images/logo/logo_ac_boost_2022-03.webp" width="200px"/>
            </div>

            <div style={{ maxWidth: "295px" }}>
              <div>
                <Button
                  className="btn border-radius-8 gradient-45deg-orange-acsp col s12"
                  style={{ fontSize: "0.8rem", color: "#fff", fontWeight: "bold", textTransform: "none" }}
                  href="documents/P76_Acb23_Regulamento_Acboost_2023_VF.pdf"
                  target="_blank"
                  startIcon={<GetApp />}
                >
                  Download do Regulamento
                </Button>
              </div>
              <div style={{ padding: "1.2rem" }}></div>
              <div style={{ marginTop: "1rem" }}>
                <Button
                  className="btn waves-effect waves-light border-radius-8 gradient-45deg-orange-acsp normal-btn col s12"
                  style={{ fontSize: "0.8rem", color: "#fff", fontWeight: "bold", textTransform: "none" }}
                  href="documents/Cronograma_AC_BOOST_23.pdf"
                  target="_blank"
                  startIcon={<GetApp />}
                >
                  Download do Cronograma Ac boost
                </Button>
              </div>
              <div style={{ padding: "1.2rem" }}></div>
              <div style={{ marginTop: "1rem" }}>
                <Button
                  className="btn border-radius-8 col s12"
                  style={{ fontSize: "0.8rem", color: "#fff", fontWeight: "bold", textTransform: "none", border: "1px solid", borderColor: "#FFF" }}
                  href="https://airtable.com/shrVeDEFbKQ9jMrk9"
                  target="_blank"
                  startIcon={<WarningOutlined />}
                >
                  Dúvidas ou problemas?
                </Button>
              </div>
            </div>
          </div>
        </div>
            
      </div>
    </div>

  );
}

export default Home;
