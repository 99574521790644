import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import {actionPitchUpdate} from './pitch.providers';
import {SystemStatus, Steps} from '../../Common/Enums';
import FormInstructions from './FormInstructions';

CheckupInvestments.propTypes = {
  successCallback: PropTypes.any,
  history: PropTypes.object,
  getPitchDetails: PropTypes.func.isRequired,
};

function CheckupInvestments(props) {

  const dispatch = useDispatch();
  const {currentCompany} = useSelector(state => state.pitch);
  const [company, setCompany] = useState(null);
  const [possibleInvestors, setPossibleInvestors] = useState([]);
  const [companyAreas, setCompanyAreas] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setCompany({...currentCompany});
    setPossibleInvestors(currentCompany.de_quem_sua_startup_ja_re ? currentCompany.de_quem_sua_startup_ja_re.split(',') : []);
    setCompanyAreas(currentCompany.qual_a_maior_necessidade ? currentCompany.qual_a_maior_necessidade.split(',') : []);
  }, [currentCompany]);

  const save = saveMode => {
    setIsSaving(true);
    company.system_status = saveMode;

    dispatch(actionPitchUpdate(company, (success, message, updateResult) => {
      if (!success) {
        toast.info(message);
      } else {
        if (updateResult.changedRows === 0) {
          toast.info('Seus dados já estão salvos. Nenhuma informação foi alterada.');
        } else {
          toast.success('💾 Informações atualizadas com sucesso!');
          props.getPitchDetails();
        }
      }
      setIsSaving(false);
    }));
  };

  const handleCompanyUpdate = (field, value) => {
    setCompany({...company, [field]: value});
  };

  const isDisabled = () => {
    return company && (currentCompany.system_status === SystemStatus.finalizado || currentCompany.etapa_atual !== Steps.etapa5.value);
  };

  const handlePossibleInvestors = (event) => {
    setPossibleInvestors(event.target.value);
    handleCompanyUpdate('de_quem_sua_startup_ja_re', event.target.value);
  };

  const possibleInvestorsList = [
    'Dos sócios',
    'Família',
    'Amigos',
    'Incubadora',
    'Investidores Anjo',
    'Subvenção Econômica – FAPESP - FINEP...',
    'Fundo de Seed / Venture Capital',
    'Aceleradora',
    'Corporativo',
    'Empresas de participações',
    'Instituições financeiras',
    'Crowdfunding',
  ];

  const handleCompanyAreas = (event) => {
    setCompanyAreas(event.target.value);
    handleCompanyUpdate('qual_a_maior_necessidade', event.target.value);
  };

  const companyAreasList = [
    'Financeiro',
    'Intelectual',
    'Desenvolvimento de produto',
    'Relacionamento como cliente',
    'Relacionamento com parceiros',
    'Estrutural',
    'Legal',
    'Tributário',
    'Governança',
    'Marketing',
    'Tecnologia',
    'Planejamento financeiro',
    'Gestão estratégica',
    'Gestão administrativa',
    'Preparação para captação de investimentos',
  ];

  return company && (
    <div className="mauticform_wrapper" style={{padding: "0.8rem"}}>

      <FormInstructions materialIconName="local_atm">
        Parte da compreensão do momento de uma startup está relacionado a composição de seu captable e qual experiência com investimentos da startup e seus fundadores. Nesta etapa vamos procurar entender como sua startup está neste cenário.
      </FormInstructions>

      <form autoComplete="false" method="post" encType="multipart/form-data">
        
        <div className="mauticform-innerform">

          <div className="mauticform-page-wrapper mauticform-page-1">

            <div className="mauticform-row mauticform-freetext mauticform-field-1">
              <h3 className="mauticform-label">
                Check-up Investimentos
              </h3>
              <div className="mauticform-freetext">
                Preencha com atenção todos os campos.
              </div>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Estágio atual de investimento:</label>
              <Select
                fullWidth
                value={company.estagio_atual_de_investim}
                onChange={e => handleCompanyUpdate('estagio_atual_de_investim', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Boot Strapping (estamos nos auto-financiando)">Boot Strapping (estamos nos auto-financiando)</MenuItem>
                <MenuItem value="Investimento Anjo">Investimento Anjo</MenuItem>
                <MenuItem value="Pré-seed">Pré-seed</MenuItem>
                <MenuItem value="Seed">Seed</MenuItem>
                <MenuItem value="Serie A">Serie A</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">De quem sua startup já recebeu algum recurso financeiro?</label>
              <Select
                multiple
                fullWidth
                value={possibleInvestors}
                onChange={!isDisabled() && handlePossibleInvestors}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                >
                {possibleInvestorsList.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox disabled={isDisabled()} checked={possibleInvestors.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Quanto já foi investido na empresa até hoje?</label>
              <Select
                fullWidth
                value={company.quanto_ja_foi_investido_n}
                onChange={e => handleCompanyUpdate('quanto_ja_foi_investido_n', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Até 100 mil">Até 100 mil</MenuItem>
                <MenuItem value="Entre 100 e 200 mil">Entre 100 e 200 mil</MenuItem>
                <MenuItem value="Entre 200 e 500 mil">Entre 200 e 500 mil</MenuItem>
                <MenuItem value="Acima de 500 mil">Acima de 500 mil</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Valuation atual estimado.</label>
              <Select
                fullWidth
                value={company.valuation_atual_imaginado}
                onChange={e => handleCompanyUpdate('valuation_atual_imaginado', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Nunca fizemos um valuation">Nunca fizemos um valuation</MenuItem>
                <MenuItem value="Abaixo de R$500 mil">Abaixo de R$500 mil</MenuItem>
                <MenuItem value="Entre R$ 500 mil e R$ 1 milhão">Entre R$ 500 mil e R$ 1 milhão</MenuItem>
                <MenuItem value="Entre R$1 e R$5 milhões">Entre R$1 e R$5 milhões</MenuItem>
                <MenuItem value="Entre R$ 5 e R$10 milhões">Entre R$ 5 e R$10 milhões</MenuItem>
                <MenuItem value="Acima de R$10 milhões">Acima de R$10 milhões</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Está buscando levantar recursos financeiros?</label>
              <Select
                fullWidth
                value={company.esta_buscando_levantar_re}
                onChange={e => handleCompanyUpdate('esta_buscando_levantar_re', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Meu foco não é recursos financeiros">Meu foco não é recursos financeiros</MenuItem>
                <MenuItem value="Sim, até R$200 mil">Sim, até R$200 mil</MenuItem>
                <MenuItem value="Sim, entre R$200 e R$500 mil">Sim, entre R$200 e R$500 mil</MenuItem>
                <MenuItem value="Sim, entre R$500 mil e R$1 milhão">Sim, entre R$500 mil e R$1 milhão</MenuItem>
                <MenuItem value="Sim, acima de R$1 milhão">Sim, acima de R$1 milhão</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Em eventuais oportunidades durante o programa Ac boost, sua startup estaria disposta a negociar equity?</label>
              <Select
                fullWidth
                value={company.esta_disposto_a_disponibi}
                onChange={e => handleCompanyUpdate('esta_disposto_a_disponibi', e.target.value)}
                disabled={isDisabled()}
              >
                <MenuItem value="Não pretendemos abrir para participações neste programa">Não pretendemos abrir para participações neste programa</MenuItem>
                <MenuItem value="Aceito Negociar">Aceito Negociar</MenuItem>
              </Select>
            </div>

            <div className="mauticform-row mauticform-select mauticform-field-34 mauticform-required">
              <label className="mauticform-label">Qual a maior necessidade de investimento da empresa no momento?</label>
              <Select
                multiple
                fullWidth
                value={companyAreas}
                onChange={!isDisabled() && handleCompanyAreas}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
                >
                {companyAreasList.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox disabled={isDisabled()} checked={companyAreas.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="mauticform-row mauticform-button-wrapper mauticform-field-37">
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0 0 1.5rem"}}>

                <>
                {!isDisabled() &&
                <button 
                  type="button" 
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.rascunho)}
                  disabled={isDisabled()}
                >
                  Salvar Rascunho
                </button>
                }

                <div style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                  {isSaving && <CircularProgress />}
                  {(isDisabled() && !isSaving) && <>Etapa Finalizada 🎉</>}
                </div>

                {!isDisabled() &&
                <button
                  type="button"
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.finalizado)}
                  disabled={isDisabled()}
                >
                  Finalizar Etapa
                </button>
                }
                </>

              </div>
            </div>

          </div>
        </div>

      </form>
    </div>
  );
}

export default CheckupInvestments;
