import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import {actionPitchUpdate} from './pitch.providers';
import {SystemStatus, Steps} from '../../Common/Enums';
import FormInstructions from './FormInstructions';

SchedulingInterview.propTypes = {
  successCallback: PropTypes.any,
  history: PropTypes.object,
  getPitchDetails: PropTypes.func.isRequired,
};

function SchedulingInterview(props) {

  const dispatch = useDispatch();
  const {currentCompany} = useSelector(state => state.pitch);
  const [company, setCompany] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setCompany({...currentCompany});
  }, [currentCompany]);

  const save = saveMode => {
    setIsSaving(true);
    company.system_status = saveMode;

    dispatch(actionPitchUpdate(company, (success, message, updateResult) => {
      if (!success) {
        toast.info(message);
      } else {
        if (updateResult.changedRows === 0) {
          toast.info('Seus dados já estão salvos. Nenhuma informação foi alterada.');
        } else {
          toast.success('💾 Informações atualizadas com sucesso!');
          props.getPitchDetails();
        }
      }
      setIsSaving(false);
    }));
  };

  const handleCompanyUpdate = (field, value) => {
    setCompany({...company, [field]: value});
  };

  const isDisabled = () => {
    return company && (currentCompany.system_status === SystemStatus.finalizado || currentCompany.etapa_atual !== Steps.etapa8.value);
  };

  return company && (
    <div className="mauticform_wrapper" style={{padding: "0.8rem"}}>

      <FormInstructions materialIconName="today">
        Parabéns! Sua startup já chegou muito longe! Agora é hora de conhecermos as mentes brilhantes por trás dessa startup única. Você já pode agendar sua entrevista com os conselheiros do Conselho de Inovação da ACSP. Enviaremos todas as orientações de agendamento diretamente no seu email. Depois disso é só cruzar os dedos e torcer! Nós não vemos a hora de começarmos essa jornada com vocês!
      </FormInstructions>

      <form autoComplete="false" method="post" encType="multipart/form-data">
        
        <div className="mauticform-innerform">

          <div className="mauticform-page-wrapper mauticform-page-1">

            <div className="mauticform-row mauticform-freetext mauticform-field-1">
              <h3 className="mauticform-label">
                Agendamento da Entrevista
              </h3>
              {/* <div className="mauticform-freetext">
                Agora é o momento de fazer o agendamento da entrevista.
              </div> */}
            </div>

            {/* <div className="mauticform-row mauticform-text mauticform-field-2 mauticform-required">
              <label className="mauticform-label">Vídeo Pitch</label>
              <input
                className="mauticform-input"
                type="text" 
                value={company && company.video_pitch}
                placeholder="URL do Youtube contendo o vídeo do pitch"
                onChange={e => handleCompanyUpdate('video_pitch', e.target.value)}
                disabled={isDisabled()}
              />
            </div> */}

            <div className="mauticform-row mauticform-button-wrapper mauticform-field-37">
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0 0 1.5rem"}}>

                <>
                {!isDisabled() &&
                <button 
                  type="button" 
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.rascunho)}
                  disabled={isDisabled()}
                >
                  Salvar Rascunho
                </button>
                }

                <div style={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
                  {isSaving && <CircularProgress />}
                  {(isDisabled() && !isSaving) && <>Etapa Finalizada 🎉</>}
                </div>

                {!isDisabled() &&
                <button
                  type="button"
                  className="mauticform-button btn btn-default"
                  onClick={() => save(SystemStatus.finalizado)}
                  disabled={isDisabled()}
                >
                  Finalizar Etapa
                </button>
                }
                </>

              </div>
            </div>

          </div>
        </div>

      </form>
    </div>
  );
}

export default SchedulingInterview;
